import {Component, HostListener, OnInit,} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {TokenParms} from 'src/app/core/models/token-parms';
import {TesterTestedListService} from '../services/tester-tested-list.service';
// import {ToastrService} from 'ngx-toastr';
import {environment} from 'src/environments/environment';
import { CompleteTesterProfileService } from '../services/complete-tester-profile.service';
import { ToastMsgService } from '../services/toast-msg.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})

export class HeaderComponent implements OnInit {

  logoutTempError1: any;
  profileImgUrl: any;
  profileDetials: any;
  showCompleteProfNotif: any;
  showMobileMenuList: boolean = false;

  constructor(
    private authSer: AuthService,
    private _router: Router,
    private _testedTesterService: TesterTestedListService,
    // public toastr: ToastrService,
    private _completeProfileServices: CompleteTesterProfileService,
    public toastMsgService: ToastMsgService,
  ) {}

  ngOnInit() {
    this._completeProfileServices.getProfile().subscribe((res)=>{
      if(res.data[0].image == ""  || res.data[0].image == null){
        this.profileImgUrl = "assets/images/icons/profile-user.png";
      } else {
        this.profileImgUrl = `${environment.apiUrl}${res.data[0].image}`;
      }
      this.profileDetials = res.data[0];
      this.checkProfileCompletion();
    })

    console.log('****************', this.profileDetials);


  }

  logout() {
    this.authSer.logout().subscribe((res) => {
      localStorage.removeItem('token_id');
      localStorage.clear();
      this.showSuccessToster();
      this._router.navigate(['/']), (this.logoutTempError1 = res);
    });
  }

  showSuccessToster() {
    // this.toastr.success('Logout successful!', 'Success!');
    this.toastMsgService.showMsg("success", "Log out successful");
  }

  checkProfileCompletion(){
    if(
      (this.profileDetials.first_name != "") &&
      (this.profileDetials.last_name != "") &&
      (this.profileDetials.username != "") &&
      (this.profileDetials.nick_name != "") &&
      (this.profileDetials.email != "") &&
      (this.profileDetials.paypal_address != "") &&
      (this.profileDetials.country != "") &&
      (this.profileDetials.language != "") &&
      (this.profileDetials.gender != "") &&
      (this.profileDetials.year != null) &&
      (this.profileDetials.device_available_to_test.length > 0)
    ){
      this.showCompleteProfNotif = false;
    } else {
      this.showCompleteProfNotif = true;
    }
  }

}
