import {Component, ElementRef, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {Router} from '@angular/router';
import {DashboardClientService} from 'src/app/shared/services/dashboard-client.service';
import {TesterTestService} from 'src/app/shared/services/tester-test.service';
import {Subscription} from 'rxjs';
import {CompleteTesterProfileService} from 'src/app/shared/services/complete-tester-profile.service';
import {SocketioService} from 'src/app/socketio.service';
import {TesterTestsComponent} from '../tester-tests/tester-tests.component';
import {RecordingOverlayService} from 'src/app/shared/services/recording-overlay.service';
import {Title} from "@angular/platform-browser";

declare var MediaRecorder: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  userName: any;
  error1: ArrayBuffer;
  data: any;
  testerDashboard: boolean = false;
  testerName: string;
  availableTest: any;
  testerGetData: Subscription;
  currentAvailableTest: any = 0;
  public href: string = '';
  url: string = 'asdf';
  totalCompletedtest: any;
  currentCompltedTest: number;
  testerNameAgain: any;
  totalEarning: any;
  ShowDashboard: boolean = false;
  checkProfileCompleted: boolean;
  customeDummyTest: any;
  @ViewChild('video', {static: false}) video: any;
  @ViewChild('screenBtn', {static: false}) screenBtn: ElementRef;
  @ViewChild('recordBtn', {static: false}) startButton: ElementRef;
  // @ViewChild('resumeBtn' ,{static:false}) resumeButton: ElementRef;
  @ViewChild('stopBtn', {static: false}) stopButton: ElementRef;
  @ViewChild(TesterTestsComponent, {static: false}) sub: TesterTestsComponent;
  // @ViewChild('pauseBtn' ,{static:false}) pauseButton: ElementRef;
  isDummyTestApproved: any;
  testerDummyTest: Subscription;
  demoTestCount: number;
  profileCmpMsg: string;
  profileUpdateMsg: string;
  rejectedTestCount: any;
  testerProfileDetails: any;
  showPopup1: boolean = false;
  private mediaRecorder: any;
  private fileName = 'demo1.mp4';

  // types of dashboard
  dashboardType: string = "preloading-data";

  // @ViewChild('downloadBtn' ,{static:false}) downloadButton: ElementRef;

  constructor(
    private _dashboardClient: DashboardClientService,
    private _router: Router,
    private _testerTestSer: TesterTestService,
    private _completeProfileServices: CompleteTesterProfileService,
    private socketioService: SocketioService,
    private recordigOverlaySer: RecordingOverlayService,
    private titleService:Title,
  ) {this.titleService.setTitle("Dashboard | UserPeek")}

  ngOnInit() {
    // this.socketioService.getMessage().subscribe((res) => {});
    this.fileName = new Date().getTime() + '_demo1.mp4';
    this.href = this._router.url;

    // this is Storre Login Api Response Usernemae in localstorage
    // this._dashboardClient.apiData$.subscribe((data) => {
    //   if (data.usertype == "client") {
    //     this.testerName = localStorage.getItem("data");
    //   }
    //   this.userName = localStorage.getItem("data");
    // });

    // var userType = localStorage.getItem("usertype");
    // if (userType == "client") {
    //   this.testerDashboard = false;
    //   this.testerName = localStorage.getItem("data");
    // } else {
    //   this.testerName = localStorage.getItem("data");
    //   this.testerDashboard = true;
    // }

    this._completeProfileServices.getProfile().subscribe((res) => {
      this.testerProfileDetails = res.data[0];
      if (
        this.testerProfileDetails.paypal_address !== null &&
        this.testerProfileDetails.zipcode !== null
      ) {
        this.profileCmpMsg = 'Your profile is completed.';
        this.profileUpdateMsg = 'You can easily update it here.';
        this.checkProfileCompleted = true;
      } else {
        this.profileCmpMsg = 'Your profile is not completed.';
        this.profileUpdateMsg = 'You can easily complete it here.';

        this.checkProfileCompleted = false;
      }
      if (res.data.length === 0) {
        this._router.navigate(['tester-Profile']);
        this.ShowDashboard = false;
      } else {
        this._router.navigate(['dashboard']);
        this.ShowDashboard = true;
      }
    });
    this.getAvailableTest();
    this.getDummyTest();
    this.getCompletedTestList();
    this.getTotalEarning();


  }

  startScreen(isApproved) {
    this._router.navigate(['tester_tests']);
    if (!isApproved) {
      setTimeout(() => {
        this.recordigOverlaySer.callComponentMethod('desktop');
      }, 1000);
    }
  }

  getDummyTest() {
    this._testerTestSer.getDummyTest().subscribe((availableTestRes) => {

      this.demoTestCount = availableTestRes.data[0].dummy_count;

      this.customeDummyTest = [
        {
          data:
            'Ready to earn rewards and gain wonderful benefits? In order to let you test websites for our customers we would love to see a ',
        },
        {data: 'Trial test'},
        {data: 'of you.'},
        {data: 'Take the Trial test in the browser'},
        {data: 'Take the Trial test'},
      ];
    });
  }

  getAvailableTest() {
    this._testerTestSer.approvedTest().subscribe((approveres) => {

      console.log('initial value ::', approveres.data);


      // if(approveres.data.length <= 0){
      //   this.dashboardType = "trial-test-not-completed";
      //   console.log('initial value || approveres.data.length <= 0 ::', approveres.data);
      // } else if(approveres.data[0].is_approved == false){
      //   this.dashboardType = "trial-test-not-completed";
      //   console.log('initial value || approveres.data[0].is_approved == false:: ', approveres.data);
      // } else if(approveres.data[0].is_approved == true){
      //   this.dashboardType = "trial-test-completed";
      //   console.log('initial value ::', approveres.data);
      // }

    if(approveres.data.length <= 0){
        this.dashboardType = "trial-test-not-completed";
    } else {
      approveres.data.every((item)=>{
        if(item.status === "APPROVED"){
          this.dashboardType = "trial-test-completed";
          return false;
        } else {
          this.dashboardType = "trial-test-not-completed";
          return true;
        }
      });
    }


      if (approveres.data && approveres.data.length > 0) {
        this.rejectedTestCount =
          approveres.data.is_approved == undefined
            ? approveres.data[0].is_approved
            : approveres.data.is_approved;

        if (approveres.data[0].is_approved == true) {
          this.isDummyTestApproved = approveres.data[0].is_approved;

          this.testerGetData = this._testerTestSer
            .getTesterTest()
            .subscribe((availableTestRes) => {
              this.availableTest = availableTestRes.data;

              if (this.availableTest == null) {
                this.currentAvailableTest = 0;
              } else {
                this.currentAvailableTest = this.availableTest.length;
              }
            });
        }
      }
    });
  }

  getCompletedTestList() {
    this._testerTestSer.getCompleteTest().subscribe((res) => {
      this.totalCompletedtest = res.data;
      if (this.totalCompletedtest.length >= 0) {
        this.currentCompltedTest = this.totalCompletedtest.length;
      } else {
        this.currentCompltedTest = 0;
      }
    });
  }

  getTotalEarning() {
    this._testerTestSer.getTotalEarning().subscribe((res) => {
      this.totalEarning = res.data;
    });
  }


  ngOnDestroy() {
    // this.testerGetData.unsubscribe()
  }
}
