import { Component, OnInit } from '@angular/core';
import { TesterTestService } from '../shared/services/tester-test.service';


@Component({
  selector: 'app-new-test-page',
  templateUrl: './new-test-page.component.html',
  styleUrls: ['./new-test-page.component.css']
})
export class NewTestPageComponent implements OnInit {


  equipment_list = ["mic","webcam","headphone"]

  constructor() { }

  ngOnInit() {
    // check dummy_test_reject_approve 
      //  if trail test completed show actual test
        // else show dummy test

    // checkTesterProfileStatus

  }



}
