import {Component, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {LoaderService} from '../services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
})
export class LoaderComponent implements OnInit {
  constructor(
    private SpinnerService: NgxSpinnerService,
    private loaderServ: LoaderService
  ) {
  }

  ngOnInit() {
    this.loaderServ.isLoding.subscribe((status) => {
      if (status) {
        this.SpinnerService.show();
      } else {
        this.SpinnerService.hide();
      }
    });
  }
}
