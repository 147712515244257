import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TestProject} from '../../core/models/testProject';
import {ApiService} from './api.service';
import {environment} from 'src/environments/environment';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UploadVideoService {
  formData: any;

  constructor(
    private http: HttpClient,
    private _apiService: ApiService,
    private _authServices: AuthService
  ) {
  }

  // Upload video For testing Purpose

  uploadVideo(testProject: TestProject): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Token ' + this._apiService.getToken(),
      }),
      params: new HttpParams(),
      reportProgress: false,
    };

    this.formData = new FormData();
    this.formData.append('file', testProject.file);
    this.formData.append('test_name', testProject.test_name);
    this.formData.append('project_name', testProject.project_name);

    return this.http.post(
      environment.apiUrl + 'test/tested_test/',
      this.formData,
      options
    );
  }

  // Get All Testing  Upload Video List

  getTestVideo(): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Token ' + this._apiService.getToken(),
      }),
      params: new HttpParams(),
      reportProgress: false,
    };
    return this.http.get(environment.apiUrl + 'test/tested_test/', options);
  }

  // get Testing Video Id

  getTestVideoId() {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Token ' + this._apiService.getToken(),
      }),
      params: new HttpParams(),
      reportProgress: false,
    };
    return this.http.get(environment.apiUrl + 'test/tested_test/', options);
  }

  testedVideoUpload(testedVideoData): Observable<any> {
    const formData = new FormData();
    formData.append('test_name', testedVideoData.test_name);
    formData.append('project_name', testedVideoData.project_name);
    formData.append('test_id', testedVideoData.test_id);
    formData.append('demo', testedVideoData.demo);
    formData.append(
      'task_details_duration_wise',
      JSON.stringify(testedVideoData.task_details_duration_wise)
    );
    formData.append('viedo_total_time', testedVideoData.viedo_total_time);
    formData.append('file', testedVideoData.file);
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Token ' + this._authServices.getToken(),
      }),
      params: new HttpParams(),
      reportProgress: true,
      observe: 'events' as 'body',
    };
    return this.http.post(
      environment.apiUrl + 'test/tested_test/',
      formData,
      options
    );
  }
}
