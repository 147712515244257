import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams,} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {BehaviorSubject, Observable, Subject, throwError} from 'rxjs';
import {environment} from 'src/environments/environment';

import {TokenParms} from '../../core/models/token-parms';
import {User} from '../../core/models/user';
import {TestProject} from './../../core/models/testProject';
import {TesterProfile} from 'src/app/core/models/tester_profile';
import {appApiResources} from './api.constant';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  AccessToken: String = '';
  accessData: any;
  formData: any;
  userRoleType = ['tester', 'client'];
  url = environment.apiUrl + 'tester/password_reset/';
  usertype = new Subject<any>();
  role = this.usertype.asObservable();
  username = new Subject<any>();
  private messageSource = new Subject();
  currentMessage = this.messageSource.asObservable();
  private currentUserSubject = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  changeMessage(currentMessage) {
    this.messageSource.next(currentMessage);
  }

  selectUserType(role) {
    this.usertype.next(role);
  }

  // This is For SignUp Api Method

  getLoadingIndicator() {
    return this.role;
  }

  register(testProfile: TesterProfile, isToken = false): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        USERTYPE: 'tester',
      }),
      params: new HttpParams(),
      reportProgress: false,
    };

    return this.http.post(
      environment.apiUrl + 'user/registration/',
      testProfile,
      options
    );
  }

  // THis is for Login Api
  login(TokenParms: TokenParms, isToken = false): Observable<any> {
    const options = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params: new HttpParams(),
      reportProgress: false,
    };

    return this.http
      .post(appApiResources.login, TokenParms, options)
      .pipe(catchError(this.handleError));
  }

  // get email Exist

  // This is use For Handle The Api Error Response
  loginasAdmin(email) {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Token ' + localStorage.getItem('token_id'),
      }),
      params: new HttpParams().set('email', email),
      reportProgress: false,
    };
    return this.http.get(appApiResources.loginAsAdmin, options);
  }

  getEmailExist(email) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: new HttpParams().set('email', email),
      reportProgress: false,
    };
    return this.http.get(appApiResources.checkEmailExist, options);
  }

  //

  setToken(token: string) {
    return localStorage.setItem('token', token);
  }

  // This is For Set The Token in to a localstorage

  getToken() {
    return localStorage.getItem('token_id');
  }

  // This is For get Token in to a localStorage

  forgetPassword(): Observable<any> {
    return this.http.post(appApiResources.passwordReset, TokenParms);
  }

  //  Forget password

  logout() {
    return this.http.get(appApiResources.userLogout);
  }

  //  Logout Api

  resetPassword() {
    return this.http.get(appApiResources.passwordReset);
  }

  //  Reset password Api

  getFOrmData() {
    return this.http.get(appApiResources.testerProfileCreate);
  }

  // This is for get profile Create Tester Profile data

  getVideo(testProject: TestProject): Observable<any> {
    const options = {
      headers: new HttpHeaders({Authorization: 'Token ' + this.getToken()}),
      params: new HttpParams(),
      reportProgress: false,
    };

    this.formData = new FormData();
    this.formData.append('file', testProject.file);
    this.formData.append('test_name', testProject.test_name);
    this.formData.append('project_name', testProject.project_name);
    return this.http.post(
      environment.apiUrl + 'test/tested_test/',
      this.formData,
      options
    );
  }

  // This is for  upload Video List

  //  This is For get Video List
  getTestVideo(): Observable<any> {
    const options = {
      headers: new HttpHeaders({Authorization: 'Token ' + this.getToken()}),
      params: new HttpParams(),
      reportProgress: false,
    };
    return this.http.get(environment.apiUrl + 'test/tested_test/', options);
  }

  getTestVideoId() {
    const options = {
      headers: new HttpHeaders({Authorization: 'Token ' + this.getToken()}),
      params: new HttpParams(),
      reportProgress: false,
    };
    return this.http.get(environment.apiUrl + 'test/tested_test/', options);
  }

  //

  loggedIn() {
    return !!localStorage.getItem('token_id');
  }

  // Remove Local Storage Data

  getRequestApi(url, isToken = true) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Token ' + this.getToken(),
      }),
    };

    return this.http.get(url, this.getHeader(isToken));
    // return this.http.get(url, httpOptions)
  }

  //  This is use FOr get Request Api

  postRequestApi(url, body = {}, isToken = true) {
    return this.http.post(url, body, this.getHeader(isToken));
  }

  // post Request Api

  putRequestApi(url, body = {}, isToken = true) {
    return this.http.post(url, body, this.getHeader(isToken));
  }

  // Put Request Api

  getHeader(isToken, isMultipart = false) {
    let httpOptions: any;

    if (isToken && isMultipart) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'multipart/form-data',
          Authorization: 'Token ' + this.getToken(),
        }),
      };
    } else if (isToken) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Token ' + this.getToken(),
        }),
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'multipart/form-data',
          USERTYPE: 'tester',
        }),
      };
    }
    return httpOptions;
  }

  checkUserCount(): Observable<any> {
    return this.http.get(appApiResources.userCount);
  }

  // getCompleteTest(): Observable<any> {
  //   return this.http.get(appApiResources.getCompletedTest);
  // }

  //  set Header

  private handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = 'Server is not resoponding';
    }
    return throwError(errorMessage);
  }
}
