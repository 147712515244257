import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastMsgService {


  public showToast$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public iconType$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public toastText$: BehaviorSubject<any> = new BehaviorSubject<any>("Default Toast Message");

  constructor() { }



  showMsg(iconType: string, toastTest: any, timeout: any = 2500){

    this.showToast$.next(true);
    this.toastText$.next(toastTest);
    this.iconType$.next(iconType);

    setTimeout(() => { 
      this.dismissToast();
    }, timeout);  

    // if(iconType == "success"){
    //   this.iconType$.next(true);
    // } else {
    //   this.iconType$.next(false);
    // }
  }


  dismissToast(){
    this.showToast$.next(false);
  }



}
