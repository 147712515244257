import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CompleteTesterProfileService} from 'src/app/shared/services/complete-tester-profile.service';
import {ModelBoxService} from 'src/app/shared/services/model-box.service';

@Component({
  selector: 'app-validate-passcode-box',
  templateUrl: './validate-passcode-box.component.html',
  styleUrls: ['./validate-passcode-box.component.css']
})
export class ValidatePasscodeBoxComponent implements OnInit {
  updateEmailForm: FormGroup;
  @Input() getOldEmail: any;
  @Output() getUpdateEmailSuccessEvt = new EventEmitter<any>();
  submitted: boolean;

  constructor(private _fb: FormBuilder, private _modelBox: ModelBoxService, private _profileService: CompleteTesterProfileService) {
  }

  ngOnInit() {
    this.initUpdateForm();
  }

  initUpdateForm() {
    this.updateEmailForm = this._fb.group({
      oldPassCode: ['', Validators.required],
      newPassCode: ['', Validators.required]
    });
  }

  ValidatePassCode() {

    this.submitted = true;
    if (this.updateEmailForm.invalid) {
      return;
    }
    this.submitted = false;
    let oldPassCode = this.updateEmailForm.value.oldPassCode;
    let newPassCode = this.updateEmailForm.value.newPassCode;
    let oldEmail = this.getOldEmail;
    let newEmail = localStorage.getItem('newEmail');
    this._profileService.validateUpdateOTP(oldPassCode, newPassCode, oldEmail, newEmail).subscribe(res => {


      if (res['status'] == 200) {
        this.setValidateDeatils(res['message'], true);
        this._modelBox.showModelBox(true, res['message'], 'Email updated', '', 'profile');

      } else {
        this._modelBox.showErrorToster(res['message']);
        this.setValidateDeatils(res['message']);

      }
    }, (error) => {
      this._modelBox.showErrorToster(error);
    });
  }

  setValidateDeatils(message, status?) {
    let ValidateStatus = status;
    let resMessage = message;
    let validatePassCodeInfo = {
      status: ValidateStatus,
      message: resMessage
    };
    this.getUpdateEmailSuccessEvt.emit(validatePassCodeInfo);
  }

}
