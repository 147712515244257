import {AbstractControl, ValidationErrors} from '@angular/forms';
// import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// export function zipcodeValidator(control: AbstractControl) {

//     if (control && (control.value !== null || control.value !== undefined)) {
//         const regex = new RegExp('^[0-9]{6}$');

//         if (!regex.test(control.value)) {
//             return {
//                 isError: true
//             };
//         }
//     }

//     return null;
// }
// export class CustomValidators {
//     static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
//       return (control: AbstractControl): { [key: string]: any } => {
//         if (!control.value) {
//           // if control is empty return no error
//           return null;
//         }

//         // test the value of the control against the regexp supplied
//         const valid = regex.test(control.value);

//         // if true, return no error (no error), else return error passed in the second parameter
//         return valid ? null : error;
//       };
//     }
// }


export const PasswordStrengthValidator = function(control: AbstractControl): ValidationErrors | null {

  let value: string = control.value || '';
  let msg = '';
  if (!value) {
    return null;
  }

  let upperCaseCharacters = /[A-Z]+/g;
  let lowerCaseCharacters = /[a-z]+/g;
  let numberCharacters = /[0-9]+/g;
  let specialCharacters = /[!@#$%^&*~()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  // let specialCharacters1 = /[-_+=?><~!]+/g;
  if (upperCaseCharacters.test(value) === false || lowerCaseCharacters.test(value) === false || numberCharacters.test(value) === false || specialCharacters.test(value) === false) {
    return {
      passwordStrength: 'Password must contain at  least one uppercase, one lower case , one special character  and a number.'
    };

  }

  /*let upperCaseCharacters = /[A-Z]+/g
  if (upperCaseCharacters.test(value) === false) {
    return { passwordStrength: `Upper case required` };
  }

  let lowerCaseCharacters = /[a-z]+/g
  if (lowerCaseCharacters.test(value) === false) {
    return { passwordStrength: `lower case required` };
  }


  let numberCharacters = /[0-9]+/g
  if (numberCharacters.test(value) === false) {
    return { passwordStrength: `number required` };
  }

  let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
  if (specialCharacters.test(value) === false) {
    return { passwordStrength: `Special char required` };
  }
   return {
    passwordStrength:null
  }*/

};


export class CustomValidator {
// Validates zip codes
  static zipCodeValidator(zip): any {
    if (zip.pristine) {
      return null;
    }
    const ZIP_REGEXP = /^[0-9]{5}(?:-[0-9]{4})?$/;
    zip.markAsTouched();
    if (ZIP_REGEXP.test(zip.value)) {
      return null;
    }
    return {
      invalidZip: true
    };
  }

// Validates numbers
  static numberValidator(number): any {
    if (number.pristine) {
      return null;
    }
    const NUMBER_REGEXP = /^-?[\d.]+(?:e-?\d+)?$/;
    number.markAsTouched();
    if (NUMBER_REGEXP.test(number.value)) {
      return null;
    }
    return {
      invalidNumber: true
    };
  }
}
