import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecordingOverlayService {

  public changeTaskOverlayCount = new BehaviorSubject<any>(0);
  public uploadTestApiCount = new BehaviorSubject<any>(0);
  public changeTaskStepsCount = new BehaviorSubject<any>(1);
  private customSubject = new Subject<any>();
  customObservable = this.customSubject.asObservable();
  private recordBtnRef = new Subject<any>();
  recordBtnObserable = this.recordBtnRef.asObservable();
  private stopBtnRef = new Subject<any>();
  stopBtnObserable = this.stopBtnRef.asObservable();
  private listofTest = new Subject<any>();
  listOfTestObserable = this.listofTest.asObservable();
  private isRecordingStart = new Subject<any>();
  isRecordingStartObserrable = this.isRecordingStart.asObservable();
  private recordingTimer = new Subject<any>();
  recordingTimerObserable = this.recordingTimer.asObservable();
  private uploadTest = new Subject<any>();
  uploadTestObserable = this.uploadTest.asObservable();


  callComponentMethod(value: any) {
    this.customSubject.next(value);
  }

  callRecordBntRefMehtod(ref: any) {
    this.recordBtnRef.next(ref);
  }

  callStopBtnRefMethod(ref: any) {
    this.stopBtnRef.next(ref);
  }

  callListOfAvlTest(list: any) {
    this.listofTest.next(list);
  }

  callCheckRecordingStart(isRecord: boolean) {
    this.isRecordingStart.next(isRecord);
  }

  callRecordingTimer(timer: any) {
    this.recordingTimer.next(timer);
  }

  callUploadTest(Test: any, task) {
    let packTaskData = [];
    packTaskData.push(Test);
    packTaskData.push(task);
    this.uploadTest.next(packTaskData);
  }


}
