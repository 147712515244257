import {Directive, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[appShowHideEvent]'
})
export class ShowHideEventDirective {
  isClickOutside: boolean = false;
  @Output()
  myCustomEvent = new EventEmitter();

  constructor() {
  }

  @HostListener('click')
  clickInside() {
    this.isClickOutside = true;

  }

  @HostListener('document:click')
  clickout() {
    if (!this.isClickOutside) {
      this.myCustomEvent.emit();


    }
    this.isClickOutside = false;
  }

}
