import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators,} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from 'src/app/shared/services/auth.service';
import {PasswordStrengthValidator} from 'src/app/constants/validators';
import Swal from 'sweetalert2';
import {Observable, Subscription} from 'rxjs';
import {CompleteTesterProfileService} from 'src/app/shared/services/complete-tester-profile.service';
import {Title} from "@angular/platform-browser";
import { environment } from 'src/environments/environment';
import {FormsUpload} from '../../models/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { appApiResources } from 'src/app/shared/services/api.constant';
import { ModelBox2Service } from 'src/app/shared/services/model-box2.service';
import { toBase64String } from '@angular/compiler/src/output/source_map';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit, AfterViewInit {
  regForm: FormGroup;
  massage: string;
  registerResMsg: string;
  userRoleType;
  errMsg = [];
  Error = false;
  submitted: boolean = false;
  passwordIsValid = false;
  @ViewChild(FormGroupDirective, {static: false})
  formGroupDirective: FormGroupDirective;
  registerResEmailMsg: any;
  hideSuccessMessage: boolean = false;
  urlType: string;
  clientSigup = false;
  fieldTextType: boolean;
  stepTwoShow: boolean = false;
  signUpStages: string = "step1";

  // Tester Complete Profile start
  public subs = new Subscription();
  public testerProfile: FormGroup;
  public submitted1: boolean = false;
  showData: any;
  showProfile: boolean = true;
  testerEdit: any = {};
  editMode: boolean = true;
  testerupdateShow: boolean = false;
  editProfile: FormsUpload;
  showDetails: boolean = false;
  language = [];
  residences = [];
  years = [];
  public checks = [];

  // This is for Device Available to Test
  message1: any;
  message: any;
  showcomp: boolean = false;
  showData1: FormsUpload[];
  data: FormsUpload[];
  testerProfileData;
  public imagePath;
  imgURL: any;
  public message3: string;
  year: number;
  profileCreateSub: Subscription;
  testerUpdateProfile: FormGroup;

  // tester
  testerEdit1: any = [];
  myDropDown = '';
  residences1 = ['Germany', 'germany', 'germany'];
  years1 = [];
  public checks1 = [];
  language1: any;
  year1: number;
  message4: string;
  imagePath1: any;
  imgURL3: any;
  imgURL1;
  public quokkaAsyncData: Observable<string>;
  public quokkaData: string;
  deviceTest: any = [];
  deviceId: any;
  createProfileShow: boolean = true;
  testerCountryValidate: any;
  showmsg: string;
  devicestotalList: any;
  isShowImageCropper: boolean = false;
  profileBase64: any;
  fileToUpload: File = null;
  private getProfile: Subscription;
  private updateProfileData: Subscription;

  // Tester complete profile End
  private getProfile1: Subscription;

  // username
  usernameError: boolean = false;
  emailExitsStatus: boolean = false;
  checkUsernameIsValid: boolean = false;
  // email
  gmailKeywordCheck: boolean = false;

  // above 18 age
  ageAbove18: boolean = true;
  disableBtn: boolean = true;
  blockSignupTemp: boolean = false;

  // paypal
  paypalErrorMsg: boolean = false;

  constructor(
    private employeeservice: AuthService,
    private formbuilder: FormBuilder,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private _authService: AuthService,
    private _router: Router,
    private _completeProfileServices: CompleteTesterProfileService, 
    private _modelBox2Sevice: ModelBox2Service,
    private titleService:Title,
  ) {this.titleService.setTitle("UserPeek Tester Signup")}
  ngAfterViewInit(): void {
    this.regForm.get('username').valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe((value) => {
      
      // valid empty space in username
      
      if(value != ""){
        if(value.indexOf(' ') >= 0){
          // space not present
          this.checkUsernameIsValid = true;
        } else{
           // space present
          this.checkUsernameIsValid = false;
        }
      }else{
        this.checkUsernameIsValid = true;
      }

      
      let options ={
        headers:{
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({"username": value.toString()})
      }
        
      async function checkValidUsername(){
        const response = await fetch(appApiResources.validateUsername, options);
        const result = await response.json();
        return result;
      }
      
      checkValidUsername().then((res)=>{
        console.log(res);
        if(res["message"] == "True"){
          this.usernameError = true;
        } else{
          this.usernameError = false; 
        }
      })
      });
      
    this.regForm.get('email').valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe((value) => {
        let options ={
          method: "GET",
        }

        let url = new URL(appApiResources.checkEmailExist);
        url.searchParams.set("email",value.toString())

        async function checkValidUsername(){
          const response = await fetch(url.toString(), options);
          const result = await response.json();
          return result;
        }
        checkValidUsername().then((res)=>{
          console.log(res);
          if(res["message"] == true){
            this.emailExitsStatus = true;
          } else{
            this.emailExitsStatus = false; 
          }
        })

        // check @gmail.com spelling correction
        let gmailKeywordValue = value.split('@');
        if( gmailKeywordValue[1] == 'gmai.com' || gmailKeywordValue[1] == 'gail.com' ||
            gmailKeywordValue[1] == 'gamil.com' || gmailKeywordValue[1] == 'gmal.com'){
          this.gmailKeywordCheck = true;
        }else {
          this.gmailKeywordCheck = false;
        }
      });
  }

  get f() {
    return this.regForm.controls;
  }

  ngOnInit() {
    this.signUpStageFlow();

    this.route.url.subscribe((url) => (this.urlType = url[0].path));
    if (this.urlType === 'client') {
      this.clientSigup = true;
    } else {
      this.clientSigup = false;
    }
    this.setRegFormState();
    this.userRoleType = this.employeeservice.userRoleType;
    this.employeeservice.role.subscribe((role) => {
    });

    // tester Start
    this.testerProfile = new FormGroup({
      username: new FormControl(''),
      email: new FormControl(''),
      password: new FormControl(''),
      extra: new FormGroup({
        language: new FormControl('', [<any> Validators.required]),
        country: new FormControl('', [<any> Validators.required]),
        first_name: new FormControl('', [<any> Validators.required]),
        last_name: new FormControl('', [<any> Validators.required]),
        nick_name: new FormControl('', [<any> Validators.required]),
        paypal_address: new FormControl('', [<any> Validators.required, Validators.email,Validators.pattern('^[A-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),]),
        // dob: new FormControl('', [<any> Validators.required,Validators.pattern('([0-9]{4}[-](0[1-9]|1[0-2])[-]([0-2]{1}[0-9]{1}|3[0-1]{1})|([0-2]{1}[0-9]{1}|3[0-1]{1})[-](0[1-9]|1[0-2])[-][0-9]{4})'),]),
        dob: new FormControl("", [Validators.required]),
        year: new FormControl(''),
        gender: new FormControl('', [<any> Validators.required]),
        image: new FormControl(null),
        device_available_to_test: this.fb.array([], [Validators.required]),
      }),
    });

    this.subs.add(
      this.testerProfile.valueChanges.subscribe((data) => {
        this._authService.changeMessage(this.testerProfile);
      })
    );

    this.year = new Date().getFullYear();
    for (let i = this.year; i >= 1940; i--) {
      this.years.push(i);
    }

    // check age is above 18
    this.validateAge()


    this.testerProfile.valueChanges.subscribe((data) =>{
      console.log('before disableBtn', this.disableBtn);
      let tempvar1 = this.testerProfile.valid;
      if(tempvar1 && this.ageAbove18){
        this.disableBtn = false;
      } else {
        this.disableBtn = true;
      }
    })

    // this.windowRef1.paypal.use( ['login'], function (login) {
    //   login.render ({
    //     "appid":"Adq87eM_VE2iZYPRWAsy6XjrajrY21dUKovcYQsNXFIHzuS5bg4e4k3z9PjGxa9adBXKENp5ckD9sdYp",
    //     "authend":"sandbox",
    //     "scopes":"email",
    //     "containerid":"lippButton",
    //     "responseType":"code",
    //     "locale":"en-us",
    //     "buttonType":"LWP",
    //     "buttonShape":"pill",
    //     "buttonSize":"lg",
    //     "fullPage":"true",
    //     "returnurl":"https://stagtester.userpeek.io"
    //   });
    // });

  
  }

  urlSafeBase64Encode(str) {
    let base64 = btoa(str);
    return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  }

  signUpStageFlow(){
    this.route.queryParams.subscribe((params) => {
      if(params['code'] != undefined){
        this.signUpStages = "step2";
        
        // flow 1: get user code
        console.log(": paypal queryParams url working",params['code'])
        let userCode = params['code'].toString();


        // flow 2: get access token
        let tempClientId = "AWO1Fase6G6MP1sJ_27KoFnuZS6HiM7PpSiXftH5101F_PGlhT404YFmxuH-nHFKJglHXe3ckLeI44Gg";
        let tempSecretkey = "ENmbpX5cgqo_S-CHeAZRn6n07dxYQBBuwDPzpHbdzlG87JPoFv-yMsuA2w8gdbntZ3lhTvX-vz6FmrKh";
        let tempString = `${tempClientId}:${tempSecretkey}`;
        let paypalCredentialsBase64 = this.urlSafeBase64Encode(tempString);
        fetch("https://api-m.paypal.com/v1/oauth2/token", {
          method: 'POST',
          headers: new Headers({
            'Authorization': `Basic ${paypalCredentialsBase64}`,
            'Content-Type': 'application/x-www-form-urlencoded'
          }),
          body: new URLSearchParams({
            'grant_type': 'authorization_code',
            'code': userCode,
          }),
        }).then((tokenResponse)=>{
            if(tokenResponse.ok){
              return tokenResponse.json();
            } else {
              // show error message
              this.paypalErrorMsg = true;
            }
          })
          .then((tokenData) => {
            console.log("tokenData", tokenData)
            // flow 3: get user profile info
            fetch("https://api-m.paypal.com/v1/identity/oauth2/userinfo?schema=paypalv1.1", {
              method: "GET",
              headers: {
                "Content-Type":"application/json",
                "Authorization": `Bearer ${tokenData["access_token"]}`,
              }
            }).then((userProfileResponse)=>{
                if(userProfileResponse.ok){
                  return userProfileResponse.json();
                } else {
                  // show error message
                  this.paypalErrorMsg = true;
                }
              })
              .then((userProfileData) => {
                let tempFullNameArray = userProfileData.name.split(" ");
                if (tempFullNameArray.length <= 1){
                  this.testerProfile.patchValue({
                    extra:{
                      first_name: tempFullNameArray[0],
                      nick_name: tempFullNameArray[0],
                      // paypal_address: userProfileData.emails[0].value,
                    }
                  })
                } 
                else if(tempFullNameArray.length > 1){
                  this.testerProfile.patchValue({
                    extra:{
                      first_name: tempFullNameArray[0],
                      last_name: tempFullNameArray[1],
                      nick_name: tempFullNameArray[0],
                      // paypal_address: userProfileData.emails[0].value,
                    }
                  })
                }
                this.testerProfile.patchValue({
                  username: sessionStorage.getItem("tempUsername"),
                  email: sessionStorage.getItem("tempEmail"),
                  password: sessionStorage.getItem("tempPassword"),
                });
                this._completeProfileServices.getCountryList().subscribe((list) => {
                  this.residences = list.country_list;
                  this.language = list.lang_list;
                  this.checks = list.device_list;
                });
                this.signUpStages = "step3";
              })
              .catch((userProfileErrorData)=> {console.log(userProfileErrorData)})
          })
          .catch((tokenErrorData)=> {console.log(tokenErrorData)})

      }
    });
  }


  saveEmailInfoTemp(){
    sessionStorage.setItem("tempUsername", this.regForm.get("username").value);
    sessionStorage.setItem("tempEmail", this.regForm.get("email").value);
    sessionStorage.setItem("tempPassword", this.regForm.get("password").value);
    
  }

  // commented for later use.
  // checkUserCount(){
  //   fetch(environment.apiUrl+'user/tester-count')
  //   .then((response) => response.json())
  //   .then((res) => {
  //     let useSignupCount = res.data.tester_count;
  //     // let useSignupCount = 412;
  //     if(useSignupCount > 1000){
  //       // Disable
  //       this.blockSignupTemp = true;
  //     } else {
  //       // enable
  //       this.blockSignupTemp = false;
  //     }
  //   });
  // }




  validateAge(){

    this.testerProfile.get('extra').get("dob").valueChanges.subscribe((value)=>{

      // get age form date of birth
      var today = new Date(),
      birthDate = new Date(value),
      age = today.getFullYear() - birthDate.getFullYear(),
      m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      // update year field from date of birth value
      this.testerProfile.patchValue({
        extra:{
          year: birthDate.getFullYear()
        }
      })
      
      // check age is above 18
      if(age >= 18){
        this.ageAbove18 = true;
      } else {
        this.ageAbove18 = false;
      }


      // based on age change disabled btn state
      let tempvar1 = this.testerProfile.valid;
      if(tempvar1 && this.ageAbove18){
       
        this.disableBtn = false;
        console.log('if block disableBtn', this.disableBtn);
      } else {
        this.disableBtn = true;
        console.log('else block disableBtn', this.disableBtn);
      }

      console.log('this.testerProfile', this.testerProfile.value);
    })


  }

  passwordValid(event) {
    this.passwordIsValid = event;
  }

  // SignUp FOrm Control

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  // Click the Button  Create the Account

  setRegFormState(): void {
    this.regForm = this.formbuilder.group({
      username:["",[Validators.required,]],
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[A-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          // Validators.maxLength(40),
          // Validators.pattern("^(?!.*[-_+=?><~!])(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&*])(.{6,})$"),
          PasswordStrengthValidator,
        ]),
      ],
      roletype: [''],
      checkMarks: ['', Validators.requiredTrue],
    });
  }

  onSignUp() {
    this.submitted1 = true;
    if (this.regForm.invalid) {
      return;
    }
    this.submitted1 = false;
    let reg = this.regForm.value;
    localStorage.setItem('role', this.urlType);
    if (!window.navigator.onLine) {
      Swal.fire({
        text: 'Server is not responding',
      });
    } else {

        if (this.emailExitsStatus == true) {
          this.stepTwoShow = false;
          this.signUpStages = "step1";
          Swal.fire(
            {
              text: 'A user with that email address already exists. ',
              background: '#f9f9f9',
              confirmButtonColor: '#1200FF',
            }
            // succ.message
          );
        } else {

          if (!this.usernameError){
            this.stepTwoShow = true;
            this.signUpStages = "step3";
          } else {
            Swal.fire(
              {
                text: 'Username already exists. Please try another one.',
                background: '#f9f9f9',
                confirmButtonColor: '#1200FF',
              }
              // succ.message
              );
          }
          
          // this.testerProfile.patchValue({
          //   username: this.regForm.value.username,
          //   email: this.regForm.value.email,
          //   password: this.regForm.value.password,
          // });
          this.testerProfile.patchValue({
            username: this.regForm.value.username,
            email: this.regForm.value.email,
            password: this.regForm.value.password,
          });

          this._completeProfileServices.getCountryList().subscribe((list) => {
            this.residences = list.country_list;
            this.language = list.lang_list;
            this.checks = list.device_list;
          });
        }
    }

    // this.regForm.markAsTouched();
  }

  // Register the Tester And Client

  reg() {
    let that = this;
    window['grecaptcha'].ready(function() {
      window['grecaptcha']
        .execute('6LcXITEaAAAAAJ-PIRcGLjGH_EaZbplqPL7Xm8kX', {
          action: 'submit',
        })
        .then(function(token) {
          that.submitted = true;

          if (that.testerProfile.invalid) {
            return;
          }
          that.submitted = false;
          let sigupData = that.testerProfile.value;
          sigupData['captchaToken'] = token;
          that.employeeservice.register(sigupData).subscribe(
            (employeeUser) => {
              var succ = employeeUser;
              if (employeeUser.status == 200) {
                let modelBody = 'Please confirm your email address to complete the registration';
                let modelHeader = 'Account created';
                sessionStorage.removeItem("tempUsername");
                sessionStorage.removeItem("tempEmail");
                sessionStorage.removeItem("tempPassword");
                that._modelBox2Sevice.showModelBox(true,modelBody,modelHeader);
                that._router.navigate(['login']);
                // Swal.fire({
                //   text: succ.message,
                //   confirmButtonText: 'Log in to your account',
                //   background: '#f9f9f9',
                //   confirmButtonColor: '#1200FF',
                // }).then(function() {
                //   that._router.navigate(['login']);
                // });
              } else {
                Swal.fire({
                  text: succ.message,
                  background: '#f9f9f9',
                  confirmButtonColor: '#1200FF',
                });
              }
              if (succ) {
                that.regForm.reset();
                localStorage.setItem('reg', JSON.stringify(succ));

                // that.router.navigate(['login']);
              } else {
                that.Error = true;
                that.massage = 'User ID/Password Wrong';
              }
            },
            (err) => {
              that.errMsg = err;
            }
          );
        });
    });
  }

  // tesetr start
  setDefaultPic() {
    this.imgURL = './../../../../assets/images/imagePlaceholder.png';
  }

  preview(files) {
    if (files.length === 0) {
      return;
    }

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message3 = 'Only images are supported.';
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }

  onFileSelect(event) {
    const file = event.target.files[0];
    this.testerProfile.get('extra.image').setValue(file);
  }

  onChange(email: string, isChecked: boolean) {
    const emailFormArray = <FormArray> (
      this.testerProfile.controls.extra['controls'].device_available_to_test
    );

    if (isChecked) {
      emailFormArray.push(new FormControl(email));
    } else {
      let index = emailFormArray.controls.findIndex((x) => x.value == email);
      emailFormArray.removeAt(index);
    }
  }

  onCheckChange(event) {
    let deviceIds = this.testerProfile['extra'].controls
      .device_available_to_test.value;

    if (event.target.checked) {
      deviceIds.push(event.target.value);
    } else {
      let index = deviceIds.indexOf(event.target.value);
      if (index > -1) {
        deviceIds.splice(index, 1);
      }
    }

    this.testerProfile.patchValue({
      device_available_to_test: deviceIds,
    });
  }

  onFileSelect1(event) {
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

    let tgt = event.target || window.event.srcElement,
      file = tgt.files[0];

    if (file.size <= 2000000) {
      toBase64(file)
        .then((res) => {
          // Image cropper Input
          this.profileBase64 = res;

          // Show Image cropper
          this.isShowImageCropper = true;
        })
        .catch((err) => {
        });
    } else {
      Swal.fire({
        title: 'Image size must be less then 2 MB!',
        showClass: {
          popup: 'animated fadeInDown faster',
        },
        hideClass: {
          popup: 'animated fadeOutUp faster',
        },
      });
    }
  }

  onImageSave(data) {
    // Hide Image cropper
    this.isShowImageCropper = false;

    if (data['status']) {
      //For view
      this.imgURL = data['image'];

      //For API
      let file = this.dataURLtoFile(data['image'], 'user.jpg');
      this.testerProfile.get('extra.image').setValue(file);
    }
  }

  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type: mime});
  }
}
