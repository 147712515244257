import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams,} from '@angular/common/http';
import {ApiService} from './api.service';
import {TesterProfile} from 'src/app/core/models/tester_profile';
import {Observable, throwError} from 'rxjs';
import {TesterProfileUpdate} from 'src/app/core/models/tester-profile-update';
import {environment} from 'src/environments/environment';
import {catchError} from 'rxjs/operators';
import {appApiResources} from './api.constant';

@Injectable({
  providedIn: 'root',
})
export class CompleteTesterProfileService {
  formData: FormData;

  constructor(private http: HttpClient, private _apiService: ApiService) {
  }

  // Create Tester Profile or Upload Tester Profile

  create(testProfile: TesterProfile, isToken = true, isMultipart = false) {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Token ' + this._apiService.getToken(),
      }),
      params: new HttpParams(),
      reportProgress: false,
    };

    this.formData = new FormData();
    this.formData.append('email', testProfile.email);
    this.formData.append('password', testProfile.password);

    this.formData.append('first_name', testProfile.first_name);
    this.formData.append('last_name', testProfile.last_name);
    this.formData.append('nick_name', testProfile.nick_name);
    this.formData.append('paypal_address', testProfile.paypal_address);

    this.formData.append('image', testProfile.image);
    // this.formData.append("birth_date", testProfile.birth_date);
    this.formData.append('year', testProfile.year);
    this.formData.append('language', testProfile.language);
    this.formData.append('country', testProfile.country);
    this.formData.append('gender', testProfile.gender);
    this.formData.append('token_id', localStorage.getItem('token_id'));
    this.formData.append(
      'device_available_to_test',
      testProfile.device_available_to_test
    );

    return this.http.post(
      environment.apiUrl + 'profile/tester_profile/',
      this.formData,
      options
    );
  }

  // Get Tester All  Profile Data

  getProfile(): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Token ' + this._apiService.getToken(),
      }),
      params: new HttpParams(),
      reportProgress: false,
    };
    return this.http.get(
      appApiResources.createrTesterProfile,
      options
    );
  }

  // Update Tester Profile

  updateProfile(updateProfile: TesterProfileUpdate) {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Token ' + this._apiService.getToken(),
      }),
      params: new HttpParams(),
      reportProgress: false,
    };

    this.formData = new FormData();
    if (updateProfile.image) {
      this.formData.append('paypal_address', updateProfile.paypal_address);
      this.formData.append('city', updateProfile.city);
      this.formData.append('zipcode', updateProfile.zipcode);
      this.formData.append('street', updateProfile.street);
      this.formData.append('full_name', updateProfile.full_name);
      this.formData.append('nick_name', updateProfile.nick_name);

      // this.formData.append("billing_address", updateProfile.billing_address);
      this.formData.append('image', updateProfile.image);
      this.formData.append('year', updateProfile.year);
      this.formData.append('dob', updateProfile.dob);
      this.formData.append('language', updateProfile.language);
      this.formData.append('country', updateProfile.country);
      this.formData.append('gender', updateProfile.gender);
      this.formData.append('token_id', localStorage.getItem('token_id'));
      this.formData.append(
        'device_available_to_test',
        updateProfile.device_available_to_test
      );
    } else {
      this.formData.append('paypal_address', updateProfile.paypal_address);
      this.formData.append('city', updateProfile.city);
      this.formData.append('zipcode', updateProfile.zipcode);
      this.formData.append('street', updateProfile.street);
      this.formData.append('first_name', updateProfile.first_name);
      this.formData.append('last_name', updateProfile.last_name);
      this.formData.append('nick_name', updateProfile.nick_name);

      // this.formData.append("billing_address", updateProfile.billing_address);
      // this.formData.append("image", updateProfile.image);
      this.formData.append('year', updateProfile.year);
      this.formData.append('dob', updateProfile.dob);
      this.formData.append('language', updateProfile.language);
      this.formData.append('country', updateProfile.country);
      this.formData.append('gender', updateProfile.gender);
      this.formData.append('token_id', localStorage.getItem('token_id'));
      this.formData.append(
        'device_available_to_test',
        updateProfile.device_available_to_test
      );
    }

    return this.http.put(
      appApiResources.createrTesterProfile,
      this.formData,
      options
    );
  }

  getCountryList(): Observable<any> {
    const options = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params: new HttpParams(),
      reportProgress: false,
    };
    return this.http.get(
      appApiResources.getCountryDeviceList,
      options
    );
  }

  deleteProfileImage() {
    return this.http
      .get(appApiResources.deleteProfileImage)
      .pipe(catchError(this.handleError));
  }

  verifyClientEmail(oldEmail, newEmail) {
    let url: any = `${appApiResources.userVerfiyEmail}?old_email=${oldEmail}&new_email=${newEmail}`;
    return this.http.get(url);
  }

  changeEmail(body) {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Token ' + this._apiService.getToken(),
      }),
      params: new HttpParams(),
      reportProgress: false,
    };
    return this.http.post(appApiResources.changeEmail, body, options);
  }

  validateEmailOTP(body) {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Token ' + this._apiService.getToken(),
      }),
      params: new HttpParams(),
      reportProgress: false,
    };
    return this.http.post(appApiResources.validateEmailOTP, body, options);
  }

  validateUpdateOTP(old_passcode, new_passcode, oldEmail, newEmail) {
    let url: any = `${appApiResources.updateEmail}?old_email_pass_code=${old_passcode}&new_email_pass_code=${new_passcode}&old_email=${oldEmail}&new_email=${newEmail}`;
    return this.http.get(url);
  }


  private handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = 'Server is not resoponding';
    }

    return throwError(errorMessage);
  }
}
