import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SplitWordsPipe} from './pipes/split-words.pipe';
import {ToggleDirective} from './directives/toggle.directive';
import {UsernameShowPipe} from './pipes/username-show.pipe';
import {SearchFilterPipe} from './pipes/search-filter.pipe';
import {TestFilterPipe} from './pipes/test-filter.pipe';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {RouterModule} from '@angular/router';
import {ShowHideEventDirective} from './directives/show-hide-event.directive';
import {LoaderComponent} from './loader/loader.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ModelBoxComponent} from './model-box/model-box.component';
import { ModelBox2Component } from './model-box2/model-box2.component';
import { FeaturedInComponent } from './featured-in/featured-in.component';
import { CustomPopupBoxComponent } from './custom-popup-box/custom-popup-box.component';

@NgModule({
  declarations: [
    SplitWordsPipe,
    ToggleDirective,
    UsernameShowPipe,
    SearchFilterPipe,
    TestFilterPipe,
    PageNotFoundComponent,
    ShowHideEventDirective,
    LoaderComponent,
    ModelBoxComponent,
    ModelBox2Component,
    FeaturedInComponent,
    CustomPopupBoxComponent,


  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxSpinnerModule

  ],
  exports: [
    SplitWordsPipe,
    UsernameShowPipe,
    SearchFilterPipe,
    TestFilterPipe,
    ShowHideEventDirective,
    LoaderComponent,
    ModelBoxComponent,
    ModelBox2Component,
    FeaturedInComponent,
    CustomPopupBoxComponent,
    
  ]


})
export class SharedModule {

}
