import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ApiCallComponent} from './core/api-call/api-call.component';
import {LoginComponent} from './core/auth/login/login.component';
import {RegisterComponent} from './core/auth/register/register.component';
import {AuthGuard} from './auth.guard';
import {PageNotFoundComponent} from './shared/page-not-found/page-not-found.component';

import {DashboardComponent} from './modules/tester/dashboard/dashboard.component';

import {CompleteTesterProfileComponent} from './modules/tester/complete-tester-profile/complete-tester-profile.component';
import {TesterTestsComponent} from './modules/tester/tester-tests/tester-tests.component';
import {LoginTesterComponent} from './core/auth/login-tester/login-tester.component';
import { HeaderOneComponent } from './header-one/header-one.component';
import { NewTestPageComponent } from './new-test-page/new-test-page.component';
import { ToastMsgComponent } from './modules/toast-msg/toast-msg.component';
import { ActivateEmailLoginComponent } from './core/auth/activate-email-login/activate-email-login.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },

  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'header-one',
    component: HeaderOneComponent
  },
  {
    path: 'new-test-page',
    component: NewTestPageComponent
  },
  {
    path: 'toast-msg',
    component: ToastMsgComponent
  },

  {
    path: 'admin-login',
    component: LoginTesterComponent
  },
  // {
  //   path: "login",
  //   component: LoginComponent
  // },

  // {
  //   path: "home",
  //   component: ApiCallComponent,
  //   canActivate: [AuthGuard]
  // },

  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'activate',
    component: ActivateEmailLoginComponent,
  },

  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'logout',
    component: ApiCallComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'tester-Profile',
    component: CompleteTesterProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'tester_tests',
    component: TesterTestsComponent,
    canActivate: [AuthGuard]
  },

  {
    path: '**',
    component: PageNotFoundComponent,
    canActivate: [AuthGuard]

  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
