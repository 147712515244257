import {Component, EventEmitter, Inject, OnDestroy, OnInit,} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import Swal from 'sweetalert2';
import {Title} from "@angular/platform-browser";

import {CompleteTesterProfileService} from 'src/app/shared/services/complete-tester-profile.service';
import {DOCUMENT} from '@angular/common';
import {environment} from 'src/environments/environment';
import {ModelBoxService} from 'src/app/shared/services/model-box.service';
import { ModelBox2Service } from 'src/app/shared/services/model-box2.service';

@Component({
  selector: 'app-complete-tester-profile',
  templateUrl: './complete-tester-profile.component.html',
  styleUrls: ['./complete-tester-profile.component.css'],
})
export class CompleteTesterProfileComponent implements OnInit, OnDestroy {
  // Model Open BackDrop Start
  baseURL = environment.apiUrl;
  onOk: EventEmitter<any> = new EventEmitter();
  onCancel: EventEmitter<any> = new EventEmitter();
  public backdrop: HTMLElement;

  // End Backdrop
  profileForm: FormGroup;
  profileForm2: FormGroup;
  countryList = [];
  languageList = [];
  yearsList = [];
  deviceList = [];
  tempJsonBody: any;
  selectedDevice: any = [];
  profileImageUrlBasePath: any = `${this.baseURL}`;
  profileImagePlaceholder: any = 'assets/images/imagePlaceholder.png';
  profileImageUrl: any;
  isShowImageCropper: boolean = false;
  base64Image: any;
  showmsg;
  isShowError: boolean = false;
  showAddModel: string = 'none';
  profileDetails: any;
  showResponseTxt: any;
  isShowValidatePassCodeBox: boolean = false;
  isShowValidatePassCodeBox2: boolean = false;
  oldEmail: any;

  profileFormValuesChanged: boolean = true;
  profileFormInitialValues: any;

  profileForm2ValuesChanged: boolean = true;
  profileForm2InitialValues: any;
  profileForm2initialArrayValue: any ;

  // update email btn validate
  updateEmailBtnStatus: boolean = true;
  validEmailOtpBtn: boolean = true;
  disableprofileForm1Btn:boolean = true;
  disableprofileForm2Btn:boolean = true;
  disableprofileForm2BtnCombined:boolean = true;
  EmailNewupdate: any;

   // above 18 age
   ageAbove18: boolean = true;

  private getProfileSub: Subscription;
  private updateProfileSub: Subscription;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private fb: FormBuilder,
    private _router: Router,
    private _completeProfileServices: CompleteTesterProfileService,
    private _modelBoxSevice: ModelBoxService,
    private _modelBox2Sevice: ModelBox2Service,
    private titleService:Title,
  ) { this.titleService.setTitle("Profile | UserPeek") }

  ngOnInit() {
    // init profile form
    this.profileForm = this.initProfileForm();
    this.profileForm2 = this.initProfileForm2();


    // get year list
    this.yearsList = this.getYearList();

    // get country,language,device and profile data
    this._completeProfileServices.getCountryList().subscribe((list) => {
      this.countryList = list.country_list;
      this.languageList = list.lang_list;
      this.deviceList = list.device_list;
      this.getProfileData();
    });

    // valid email update otp btn status active or inactive 
    this.validEmailOTPBtn();

  }

  // @note initialize from
  initProfileForm() {
    return this.fb.group({
      image: [''],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      nick_name: ['', Validators.required],
      username: [''],
      paypal_address: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[A-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      street: [''],
      zipcode: [''],
      city: [''],
      email: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[A-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ],],
      otp: [''],
    });
  }

  initProfileForm2() {
    return this.fb.group({
      country: ['', Validators.required],
      language: [''],
      year: [''],
      dob: [''],
      gender: [''],
      device_available_to_test: [[], Validators.required],
    });
  }

  successEmailUpdateEvy(event) {
    this.showResponseTxt = '';
    if (event.status) {
      this.isShowValidatePassCodeBox = false;
      this.isShowValidatePassCodeBox2 = false;

    } else {
      this.showResponseTxt = event.message;
    }
  }

  getYearList() {

    let yearList = [];
    let getCurrentYear = new Date().getFullYear();
    for (let i = getCurrentYear; i >= 1940; i--) {
      yearList.push(i);
    }
    return yearList;
  }

  // @note update email button validation for disable or active state
  emailBtnValidation(){
    let tempPreviousEmailValue = this.profileFormInitialValues.email;
    // console.log('tempPreviousEmailValue', tempPreviousEmailValue);

    this.profileForm.get("email").valueChanges.subscribe((value): any => {
      this.EmailNewupdate = value;
      if(tempPreviousEmailValue === value){
        this.updateEmailBtnStatus = true;
      } else{
        this.updateEmailBtnStatus = false;
      }
    })
    
  }


  // @note update email otp btn active or inactive state
  validEmailOTPBtn(){
    this.profileForm.get("otp").valueChanges.subscribe((value: String) =>{

      console.log('length ***',value.length, value );
      
      if(value.length == 6){
        this.validEmailOtpBtn = false;
      } else {
        this.validEmailOtpBtn = true;
      }
    })
  }


  profileForm1ValidBtn(){

    let tempFormValue = this.profileFormInitialValues;

    this.profileForm.valueChanges.subscribe((value) =>{

      if(tempFormValue.last_name === value.last_name
        && tempFormValue.nick_name === value.nick_name
        && tempFormValue.username === value.username
        && tempFormValue.paypal_address === value.paypal_address
        && tempFormValue.first_name === value.first_name
        ){
        this.disableprofileForm1Btn = true;
      } else {
        this.disableprofileForm1Btn = false;
      }

    })

  }

  // @note current
  profileForm2ValidBtn(){

    let tempFormValue = this.profileForm2InitialValues;

    this.profileForm2.valueChanges.subscribe((value) =>{

      if(tempFormValue.country === value.country
        && tempFormValue.language === value.language
        && tempFormValue.dob === value.dob
        && tempFormValue.gender === value.gender
        && localStorage.getItem("profileForm2initialArrayValue") == JSON.stringify(this.profileForm2.value.device_available_to_test)
        ){
        this.disableprofileForm2Btn = false;
      } else {
        this.disableprofileForm2Btn = true;
      }

      console.log('disableprofileForm2Btn ',this.disableprofileForm2Btn );
      console.log('ageAbove18 ',this.ageAbove18 );


      if(this.disableprofileForm2Btn && this.ageAbove18){
        this.disableprofileForm2BtnCombined = false;
        console.log(' profileForm2ValidBtn if block disableprofileForm2BtnCombined', this.disableprofileForm2BtnCombined);
      } else {
        this.disableprofileForm2BtnCombined = true;
        console.log(' profileForm2ValidBtn else block disableprofileForm2BtnCombined', this.disableprofileForm2BtnCombined);
      }

        
    })

  }


  
  validateAge(){

    this.profileForm2.get("dob").valueChanges.subscribe((value)=>{

      // get age form date of birth
      var today = new Date(),
      birthDate = new Date(value),
      age = today.getFullYear() - birthDate.getFullYear(),
      m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      // update year field from date of birth value
      this.profileForm2.patchValue({
          year: birthDate.getFullYear()
      })
      
      // check age is above 18
      if(age >= 18){
        this.ageAbove18 = true;
      } else {
        this.ageAbove18 = false;
      }


      // based on age change disabled btn state
      // let tempvar1 = this.testerProfile.valid;
      if(this.disableprofileForm2Btn && this.ageAbove18){
        this.disableprofileForm2BtnCombined = false;
        console.log('validateAge if block disableprofileForm2BtnCombined', this.disableprofileForm2BtnCombined);
      } else {
        this.disableprofileForm2BtnCombined = true;
        console.log('validateAge else block disableprofileForm2BtnCombined', this.disableprofileForm2BtnCombined);
      }

    })


  }


  // @note fetch "form data" server -> to Angular.
  getProfileData() {
    this.getProfileSub = this._completeProfileServices
      .getProfile()
      .subscribe((profile) => {
        localStorage.setItem('data', profile.data[0].full_name);
        this.profileDetails = profile.data[0];
        // set profile image url
        this.profileImageUrl = profile.data[0].image
          ? `${this.profileImageUrlBasePath}${profile.data[0].image}`
          : null;

        // set profile data "profileForm"
        this.profileForm.patchValue({
          username: profile.data[0].username,
          nick_name: profile.data[0].nick_name,
          first_name: profile.data[0].first_name,
          last_name: profile.data[0].last_name,
          paypal_address: profile.data[0].paypal_address,
          street: profile.data[0].street,
          zipcode: profile.data[0].zipcode,
          city: profile.data[0].city,
          email: profile.data[0].email
        });

        // set profile data "profileForm2"
        this.profileForm2.patchValue({
          country: profile.data[0].country,
          language: profile.data[0].language,
          year: profile.data[0].year,
          dob: profile.data[0].dob,
          gender: profile.data[0].gender,
          device_available_to_test: profile.data[0].device_available_to_test,
        });

        // set selected device "profileForm"
        profile.data[0].device_available_to_test.filter((res) => {
          this.selectedDevice.push(res);
        });


        // disable "profileForm btn" logic
        this.profileForm.valueChanges.subscribe((e)=>{

          if(JSON.stringify(this.profileFormInitialValues) == JSON.stringify(this.profileForm.value)){
            this.profileFormValuesChanged = true;
          } else{
            this.profileFormValuesChanged = false;
          }

        })
        this.profileFormValuesChanged = true;
        this.profileFormInitialValues = this.profileForm.value;


        // disable "profileForm2 btn" logic
        this.profileForm2.valueChanges.subscribe((e)=>{

          
          if((JSON.stringify(this.profileForm2InitialValues) == JSON.stringify(this.profileForm2.value)) && 
          (localStorage.getItem("profileForm2initialArrayValue") == JSON.stringify(this.profileForm2.value.device_available_to_test))){
            this.profileForm2ValuesChanged = true;
          } else{
            this.profileForm2ValuesChanged = false;
          }
        })

        this.profileForm2ValuesChanged = true;
        this.profileForm2InitialValues = this.profileForm2.value;
        localStorage.setItem("profileForm2initialArrayValue", JSON.stringify(this.profileForm2.value.device_available_to_test));

        // update email btn active or inactive
        this.emailBtnValidation();
        this.profileForm1ValidBtn();
        this.profileForm2ValidBtn();
        this.validateAge();
      });

      
  }

  // @note on form submit -> calls this function
  updateProfile() {
    this.isShowError = false;

    // if form is invalid, return and show error
    if (this.profileForm.invalid) {
      this.isShowError = true;
      return;
    }

    this.tempJsonBody = {
      ...this.profileForm.value,
      ...this.profileForm2.value,
    };

    this.updateProfileSub = this._completeProfileServices
      .updateProfile(this.tempJsonBody )
      .subscribe((updateProfile) => {
        if (updateProfile['status'] == 400) {
          // show update error message
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: updateProfile['message'],
          });
        } else {
          window.location.reload();
          this._modelBoxSevice.showModelBox(true, ' Your profile was successfully updated.', 'Profile updated', '', 'profile');
        }

      });
  }

  // @note on form submit -> calls this function
  updateProfileImage() {
    this.isShowError = false;
    // if form is invalid, return and show error
    if (this.profileForm.invalid) {
      this.isShowError = true;
      return;
    }
    this.tempJsonBody = {
      ...this.profileForm.value,
      ...this.profileForm2.value,
    };
    this.updateProfileSub = this._completeProfileServices
      .updateProfile(this.tempJsonBody )
      .subscribe((updateProfile) => {
        if (updateProfile['status'] == 400) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: updateProfile['message'],
          });
        } else {
          window.location.reload();
          this._modelBoxSevice.showModelBox(true, ' Your profile image was successfully updated.', 'Profile image updated', '', 'profile');
        }
      });
  }
  
  updateProfile2() {
    // console.log('callled !!! 1',this.profileForm2.value);
    this.isShowError = false;

    // if form is invalid, return and show error
    if (this.profileForm2.invalid) {
      this.isShowError = true;
      return;
    }

    this.tempJsonBody = {
      ...this.profileForm.value,
      ...this.profileForm2.value,
    };
    this.updateProfileSub = this._completeProfileServices
      .updateProfile(this.tempJsonBody)
      .subscribe((updateProfile) => {
        if (updateProfile['status'] == 400) {
          // show update error message
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: updateProfile['message'],
          });
        } else {
          window.location.reload();
          this._modelBoxSevice.showModelBox(true, ' Your profile was successfully updated.', 'Profile updated', '', 'profile');
        }

      });
  }

  showBackdrop() {
    this.backdrop = this.document.createElement('div');
    this.backdrop.classList.add('modal-backdrop');
    this.backdrop.classList.add('show');
    this.document.body.appendChild(this.backdrop);
  }

  hideBackdrop() {
    this.backdrop.remove();
  }

  closeSuccessModel(isOk) {
    if (isOk) {
      this.hideBackdrop();
      this.showAddModel = 'none';
      this._router.navigate(['dashboard']);
    }
  }

  onImageChange(event) {
    console.log('onImageChange fun called');
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

    let tgt = event.target || window.event.srcElement,file = tgt.files[0];

    if (file.size <= 2000000) {
      toBase64(file)
        .then((res) => {
          // Image cropper Input
          this.base64Image = res;

          // Show Image cropper
          this.isShowImageCropper = true;

          console.log('file.size <= 2000000 fun called');
        })
        .catch((err) => {
        });
    } else {
      Swal.fire({
        text: 'Image size must be less then 2 MB. ',
      });
    }
  }

  dataURLtoFile(dataurl, filename) {
    console.log('dataURLtoFile fun called');
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
  }

  onImageSave(data) {
    console.log('onImageSave fun called');
    // Hide Image cropper
    this.isShowImageCropper = false;

    if (data['status']) {
      //For view
      this.profileImageUrl = data['image'];

      //For API
      let file = this.dataURLtoFile(data['image'], 'user.jpg');
      this.profileForm.get('image').setValue(file);
      this.updateProfileImage();

    }
  }

  // @note modification in device list
  selectDevice(event) {

    let deviceIds = this.profileForm2.controls.device_available_to_test.value;

    if (event.target.checked) {
      deviceIds.push(event.target.value);
    } else {
      let index = deviceIds.indexOf(event.target.value);
      if (index > -1) {
        deviceIds.splice(index, 1);
      }
    }

    this.profileForm2.patchValue({
      device_available_to_test: deviceIds,
    });
    
  }


  removeProfileImage() {
    this._completeProfileServices.deleteProfileImage()
    .subscribe((res) => {
      if (res['status'] == 200) {
        this._modelBoxSevice.showModelBox(true, ' Your profile image was deleted.', 'Profile image deleted', '', 'profile');
        window.location.reload();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        });
      }
    });
  }

  // @note email change
  verfiyEmailAddress() {
    this.showResponseTxt = '';
    this.oldEmail = this.profileDetails.email;
    let newEmail = this.profileForm.value.email;
    this._completeProfileServices.verifyClientEmail(this.oldEmail, newEmail).subscribe(res => {
      if (res['status'] == 200) {
        localStorage.setItem('newEmail', newEmail);
        this.isShowValidatePassCodeBox = true;
        this.showResponseTxt = res['message'];
        this._modelBoxSevice.showSuccessToster(res['message']);

      } else {
        this.showResponseTxt = res['message'];
        this._modelBoxSevice.showErrorToster(res['message']);

      }
    });
  }

  // @note email change
  changeEmail() {
    this.showResponseTxt = '';

    this.oldEmail = this.profileDetails.email;
    let newEmail = this.profileForm.value.email;

    if (this.oldEmail != newEmail){
      this.showResponseTxt = '';
      let body ={
        "old_email" : this.oldEmail,
        "new_email" :  this.profileForm.value.email
      }
      this._completeProfileServices.changeEmail(body).subscribe(res => {
        if (res['status'] == 200) {
          this.profileForm.patchValue({
            otp: ""
          });
          localStorage.setItem('newEmail', newEmail);
          this.isShowValidatePassCodeBox2 = true;
          this._modelBoxSevice.showSuccessToster(res['message']);
  
        } else {
          this.showResponseTxt = res['message'];
          this._modelBoxSevice.showErrorToster(res['message']);
        }
      });
    } else{
      this.showResponseTxt = "Please enter your new email";
    }
  }

  // @note email change
  validateOTP2(){
    this.showResponseTxt = '';
    this.oldEmail = this.profileDetails.email;
    let newEmail = localStorage.getItem('newEmail');
    let body ={
      "otp" : this.profileForm.value.otp,
      "old_email" : this.oldEmail,
      "new_email" :  newEmail
    }
    this._completeProfileServices.validateEmailOTP(body).subscribe(res => {
      if (res['status'] == 200) {
        this.isShowValidatePassCodeBox2 = false;
        this.profileForm.patchValue({
          otp: "",
        });
        let modelBody = 'Your email was successfully updated.';
        let modelHeader = 'Email updated!';
        this._modelBoxSevice.showModelBox(true, modelBody, modelHeader);
        window.location.reload();
      } else {
        let modelBody = 'Email address not updated!';
        let modelHeader = 'OTP incorrect';
        this._modelBox2Sevice.showModelBox(false, modelBody, modelHeader);
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.updateProfileSub) {
      this.updateProfileSub.unsubscribe();
    }

    if (this.getProfileSub) {
      this.getProfileSub.unsubscribe;
    }
  }
}
