import {Component, ElementRef, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {TesterTestService} from 'src/app/shared/services/tester-test.service';
import {Subscription} from 'rxjs';
import {HttpClient, HttpEventType} from '@angular/common/http';
import {SocketioService} from 'src/app/socketio.service';
import {UploadVideoService} from 'src/app/shared/services/upload-video.service';
import getBlobDuration from 'get-blob-duration';
import {RecordingOverlayComponent} from '../recording-overlay/recording-overlay.component';
import {RecordingOverlayService} from 'src/app/shared/services/recording-overlay.service';
import {LoaderService} from 'src/app/shared/services/loader.service';
import {Title} from "@angular/platform-browser";
import {CustomPopupBoxService} from 'src/app/shared/services/custom-popup-box.service';

declare var MediaRecorder: any;
declare var totalTimeDuration: any;
var root = this;

@Component({
  selector: 'app-tester-tests',
  templateUrl: './tester-tests.component.html',
  styleUrls: ['./tester-tests.component.css'],
})
export class TesterTestsComponent implements OnInit, OnDestroy {
  testerGetData: Subscription;
  availableTest = [];
  completedList = [];
  notestComplete = false;
  ShowAvlTest: boolean = true;
  completedTestShow: boolean = false;
  completedTestCount: any;
  availableTestCount: any;
  deviceType: any;
  availableDemoTest: any;
  testerDummyTest: Subscription;
  showAvlDummyTest: boolean = false;
  dummyTestShow: boolean;
  trialTestCount: any;
  // private chunks = [];
  public logs = [];
  showModalBox: boolean = false;
  @ViewChild('appDialog', {static: false})
  appDialog: RecordingOverlayComponent;
  @ViewChild('closeBtn', {static: false}) closeBtn: ElementRef;
  @ViewChild('testsubmited_Modal', {static: false})
  testsubmited_Modal: ElementRef;
  @ViewChild('video', {static: false}) video: any;
  @ViewChild('screenBtn', {static: false}) screenBtn: ElementRef;
  // @ViewChild("recordBtn", { static: false }) startButton: ElementRef;
  @ViewChild('pauseBtn', {static: false}) pauseButton: ElementRef;
  @ViewChild('resumeBtn', {static: false}) resumeButton: ElementRef;
  // @ViewChild("stopBtn", { static: false }) stopButton: ElementRef;
  @ViewChild('downloadBtn', {static: false}) downloadButton: ElementRef;
  startRecoIndex: any;
  matchIndex: any;
  totalTimeCalculate: any;
  isModelDisplay: string;
  videoSubmitMessage: string;
  startButton: any;
  stopButton: any;
  shareScreenFnCount: number;
  currentAVlTest: any;
  progressBarPercentage: number;
  isOpenProgressBar: string = 'none';
  uploadingTitle: string;
  uploadingChangeStatus: number;
  isDemoTestSelect: any;
  noTestAvailableMsg: string;
  uploadedSelectTest: any;
  destroyUploadApi: Subscription;
  isVideoTestApproved: any;
  renderDummyTextMsg: string;
  recordBtnDestroy: Subscription;
  // for Pgaination side
  p1: number = 1;
  p2: number = 1;
  // private stream: MediaStream;
  private mediaRecorder: any;
  private chunkSize = 1000;
  private chunksReceived = 0;
  private maxRecordingLimit = 1200000;
  private fileName = 'demo1.mp4';
  private recordingStatus: string;

  takeTestBtnActive: boolean = true;
  currentDeviceType: string = '';

  showTesterPopup: boolean = false;
  testerPopupContentType: string = "";

  constructor(
    private _testerTestSer: TesterTestService,
    private socketioService: SocketioService,
    private http: HttpClient,
    private _uploadVideoSer: UploadVideoService,
    private _socectServ: SocketioService,
    private dialogService: RecordingOverlayService,
    private loaderService: LoaderService,
    private titleService:Title,
    public _customPopupBoxService: CustomPopupBoxService,
  ) {this.titleService.setTitle("Tests | UserPeek")}

  public async ngOnInit() {
    this.checkSupportedDevice();
    this.validateDeviceTypeAndTestType();
    this.fileName = new Date().getTime() + '_demo1.mp4';

    this.getAvailableTest();
    this.getCompletedTestList();
    this.getDummyTest();
    this.getDeviceType();
    this.socketioService.getMessage().subscribe((res) => {
      this.startScreenShare();
    });
    this.getTestIndex(this._socectServ.startRecoIndex, undefined, undefined);
    this.matchIndex = this._socectServ.matchIndex;
    this.recordBtnDestroy = this.dialogService.recordBtnObserable.subscribe(
      (res) => {
        this.startButton = res;
      }
    );
    this.dialogService.stopBtnObserable.subscribe((res) => {
      this.stopButton = res;
    });

    this.destroyUploadApi = this.dialogService.uploadTestObserable.subscribe(
      (uploadTest) => {
        if (uploadTest) {
          this.uploadedSelectTest = uploadTest[1];
          this.stopRecording(uploadTest[0], uploadTest[1]);
        }
      }
    );
  }

  getDeviceType(){
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      this.deviceType = "Mobile";
    } else {
      this.deviceType = "Desktop";
    }
  }

  open() {
    this.showModalBox = true;
  }

  // new screen recoding code start

  async startScreenShare() {
    // this.shareScreenFnCount = 0;
    this.dialogService.changeTaskOverlayCount.subscribe((count) => {
      this.shareScreenFnCount = count++;
    });
    let increaseCount = this.shareScreenFnCount;
    increaseCount = increaseCount + 1;
    this.dialogService.changeTaskOverlayCount.next(increaseCount);
    let desktopStream: MediaStream;
    let voiceStream: MediaStream;
    if (this.shareScreenFnCount == 1) {
      try {
        const mediaDevices = navigator.mediaDevices as any;

        desktopStream = await mediaDevices.getDisplayMedia({
          video: true,
          audio: false,
        });
        voiceStream = await mediaDevices.getUserMedia({
          video: false,
          audio: true,
        });

        const tracks = [
          ...desktopStream.getVideoTracks(),
          ...voiceStream.getAudioTracks(),
        ];

        this._socectServ.stream = new MediaStream(tracks);

        let video: HTMLVideoElement = this.video.nativeElement;
        video.muted = true;
        video.controls = false;
        video.srcObject = this._socectServ.stream;
        video.style.display = 'none';
        video.play();
        this._socectServ.stream.addEventListener(
          'inactive',
          () => {
            this.stopRecording(undefined, undefined);
          },
          false
        );

        this._socectServ.stream.addEventListener(
          'ended',
          () => {
            this.stopRecording(undefined, undefined);
          },
          false
        );

        // let screenBtn = this.screenBtn.nativeElement;
        // screenBtn.disabled = true;
        let startButton = this.startButton.nativeElement;

        startButton.disabled = false;
        this.dialogService.callStopBtnRefMethod('recordStart');

        this.logs.push('Screen sharing started.');
      } catch (e) {
        this.shareScreenFnCount = 0;
        this.dialogService.callStopBtnRefMethod(e);
        if (desktopStream) {
          desktopStream.getTracks().forEach((s) => s.stop());
          this.logs.push('Microphone sharing rejected.');
        }

        if (voiceStream) {
          voiceStream.getTracks().forEach((s) => s.stop());
          this.logs.push('Screen sharing rejected.');
        }
      }
    }
  }


  // genericFunction(selectedTestData){
  //   this._testerTestSer.takeTest(selectedTestData).subscribe();
  // }

  getTestIndex(i, selectTest, isDemoTestSelect) {
    this.chunksReceived = 0;
    if (selectTest) {
      this._socectServ.startRecoIndex = i;
      this.startRecoIndex = this._socectServ.startRecoIndex;

      this.currentAVlTest = selectTest;
      this.isDemoTestSelect = isDemoTestSelect;
      // this.dialogService.callComponentMethod(this.currentAVlTest);
    }

  }

  startRecordingCall(i, event) {
    this.startRecording(i, event);
    console.log("checking blocked start");
    // this.checkSupportedDevice();
  }



  startRecording(i, event) {
    this.startButton = event;
    this._socectServ.matchIndex = i;
    this.matchIndex = this._socectServ.matchIndex;
    localStorage.removeItem('totalTimeduration');
    this.socketioService.connectionInit();
    this.socketioService.socket.on('reconnect', () => {
      // this.resumeRecording();
    });
    this.socketioService.socket.on('disconnect', () => {
      // this.pauseRecording();
    });

    this.logs.push('recording starting.');

    // let options = {mimeType: 'video/webm; codecs=h264'};
    let options = {mimeType: 'video/webm; codecs=vp8,opus'};

    try {
      this.mediaRecorder = new MediaRecorder(this._socectServ.stream, options);
      this.mediaRecorder.ondataavailable = this.handleDataAvailable;
      this.mediaRecorder.start(this.chunkSize);

      let startButton = this.startButton.nativeElement;
      startButton.disabled = true;

      // let pauseButton = this.pauseButton.nativeElement;
      // pauseButton.disabled = false;

      // let stopButton = this.stopButton.nativeElement;
      // stopButton.disabled = false;
      // this.closeBtn.nativeElement.click();

      this.logs.push('recording started.');
      this.dialogService.callCheckRecordingStart(true);
    } catch (e) {
      this.logs.push('recording error.');
      this.dialogService.callCheckRecordingStart(false);

      alert(e);
      this.closeBtn.nativeElement.click();
    }
  }

  stopRecording(data, uploadTest): void {
    // this.SpinnerService.show();

    this.logs.push('recording stopping.');
    this._socectServ.stream.getTracks().forEach((s) => s.stop());

    if (this.mediaRecorder && this.mediaRecorder.state == 'active') {
      this.mediaRecorder.stop();
    }

    // let stopButton = this.stopButton.nativeElement;
    // stopButton.disabled = true;

    // let downloadButton = this.downloadButton.nativeElement;
    // downloadButton.disabled = false;

    // let pauseButton = this.pauseButton.nativeElement;
    // pauseButton.disabled = true;

    setTimeout(() => {
      // let blob = new Blob(this._socectServ.chunks, {type: 'video/mp4'});
      let blob = new Blob(this._socectServ.chunks, {type: 'video/webm'});
      let blob1 = new File([blob], this.fileName, {
        lastModified: 1534584790000,
      });

      let video: HTMLVideoElement = this.video.nativeElement;

      video.srcObject = null;
      getBlobDuration(blob).then(function(duration) {
        let data = JSON.stringify(duration);
        var sec_num = parseInt(data, 10); // don't forget the second param
        var hours: any = Math.floor(sec_num / 3600);
        var minutes: any = Math.floor((sec_num - hours * 3600) / 60);
        var seconds: any = sec_num - hours * 3600 - minutes * 60;

        if (hours < 10) {
          hours = '0' + hours;
        }
        if (minutes < 10) {
          minutes = '0' + minutes;
        }
        if (seconds < 10) {
          seconds = '0' + seconds;
        }
        localStorage.setItem('totalTimeduration', minutes + ':' + seconds);
        return duration;
      });

      if (uploadTest) {
        setTimeout(() => {
          let parms = {
            test_name: data.test_name,
            project_name: data.project_name,
            test_id: data.id,
            demo: this.isDemoTestSelect,
            task_details_duration_wise: {Task: uploadTest},
            viedo_total_time: localStorage.getItem('totalTimeduration'),
            file: blob1,
          };
          this.loaderService.isLoding.next(false);
          this._uploadVideoSer.testedVideoUpload(parms).subscribe(
            (res) => {
              if (res.type === HttpEventType.Response) {
                this.uploadingChangeStatus = 1;
                this.uploadingTitle = 'Thank you!';
                this.isOpenProgressBar = 'block';
                this.progressBarPercentage = null;
                this.showModalBox = true;
                this.isModelDisplay = 'block';
                if (this.isDemoTestSelect == true) {
                  this.videoSubmitMessage = 'Please wait for the approval';
                } else {
                  this.videoSubmitMessage = 'Test Submitted';
                }
                this.getTestIndex(null, null, null);
                this.matchIndex = 1;
                // this.chunksReceived = 0;
                this.getAvailableTest();
                this.getCompletedTestList();
                this.getDummyTest();
              } else if (res.type === HttpEventType.UploadProgress) {
                this.uploadingChangeStatus = 2;
                this.uploadingTitle = 'Uploading...';
                this.isOpenProgressBar = 'block';
                const percentDone = Math.round((100 * res.loaded) / res.total);
                this.progressBarPercentage = percentDone;

                // console.log("Progress " + percentDone + "%");
              } else if (res.status == 400) {
                // this.chunksReceived = 0;
                this.isOpenProgressBar = 'block';
                this.uploadingChangeStatus = 3;
                this.uploadingTitle = 'An error occured';

                // this.isOpenProgressBar = "none";
              }
            },
            (error) => {
              document.getElementById('openModalButton').click();
              this.isOpenProgressBar = 'block';
            }
          );
        }, 2000);
      } else {
        window.document.title = 'userpeek';
        // this.chunksReceived = 0;
      }

      this.logs.push('recording stopped.');
      // this.testsubmited_Modal.nativeElement.click();
    }, 1000);
  }

  onMyCustomEvent() {
    this.showModalBox = false;
    this.isModelDisplay = 'none';
  }

  closePopup() {
    window.document.title = 'userpeek';
    this.showModalBox = false;
    this.isModelDisplay = 'none';
    this.isOpenProgressBar = 'none';
    this.uploadingChangeStatus = 0;
  }

  refreshPage(){
    window.location.reload();
  }


  pauseRecording(): void {
    this.logs.push('recording pausing.');
    this.mediaRecorder.pause();

    let pauseButton = this.pauseButton.nativeElement;
    pauseButton.disabled = true;

    let resumeButton = this.resumeButton.nativeElement;
    resumeButton.disabled = false;
    this.logs.push('recording paused.');
  }

  resumeRecording(): void {
    this.logs.push('recording resuming.');

    this.mediaRecorder.resume();

    let pauseButton = this.pauseButton.nativeElement;
    pauseButton.disabled = false;

    let resumeButton = this.resumeButton.nativeElement;
    resumeButton.disabled = true;

    this.logs.push('recording resumed.');
  }

  handleDataAvailable = (event) => {
    if (event.data && event.data.size > 0) {
      this._socectServ.chunks.push(event.data);
      this.socketioService.emit('blobs', {
        file_name: this.fileName,
        data: event.data,
      });
      this.chunksReceived += this.chunkSize;
      this.msConversion(this.chunksReceived);

      if (this.chunksReceived >= this.maxRecordingLimit) {
        // this.stopRecording(undefined,this.uploadedSelectTest);
      }
    }
  };

  msConversion(millis) {
    let sec: any = Math.floor(millis / 1000);
    let hrs = Math.floor(sec / 3600);
    sec -= hrs * 3600;
    let min: any = Math.floor(sec / 60); // 300/60 = 5
    sec -= min * 60;

    if (min.toString().length == 1) {
      min = '0'+min.toString()
    }
    
    sec = '' + sec;
    sec = ('00' + sec).substring(sec.length);
    if (hrs > 0) {
      min = '' + min;
      min = ('00' + min).substring(min.length);
      // return console.log(hrs + ":" + min + ":" + sec)
      return this.dialogService.callRecordingTimer(hrs + ':' + min + ':' + sec);
    } else {
      // console.log(min + ":" + sec);
      return this.dialogService.callRecordingTimer(min + ':' + sec);
    }
  }

  download() {
    let blob = new Blob(this._socectServ.chunks, {
      type: 'video/webm',
    });

    let url = URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'record.webm';

    a.click();
  }

  // end screen recoding code

  getDummyTest() {

    this.testerDummyTest = this._testerTestSer
      .getDummyTest()
      .subscribe((availableTestRes) => {


        this.trialTestCount = availableTestRes.data[0].dummy_count;

        if(availableTestRes.data[0].dummy_count == 0){
          this.trialTestCount = 2;
        } else if(availableTestRes.data[0].dummy_count == 1){
          this.trialTestCount = 1;
        } else{
          this.trialTestCount = 0;
        }




        if (
          availableTestRes.data[0].dummy_count == 0 &&
          this.isVideoTestApproved == null
        ) {
          // this.showAvlDummyTest = false;
          // this.noTestAvailableMsg =
          //   "You do not have any available dummy tests!";

          this.showAvlDummyTest = true;
          this.dummyTestShow = false;
        } else if (
          availableTestRes.data[0].dummy_count == 1 &&
          this.isVideoTestApproved == false
        ) {
          this.showAvlDummyTest = true;
          this.dummyTestShow = false;
        } else {
          this.showAvlDummyTest = false;
          this.noTestAvailableMsg = `We have no available tests for you. Please come back later or check
          your email for new tests.`;
        }
        if (availableTestRes.data == null) {
          return;
        } else {
          this.availableDemoTest = availableTestRes.data.sort((a, b) => {
            return (
              +new Date(b['creation_date']) - +new Date(a['creation_date'])
            );
          });
          this.currentAVlTest = this.availableDemoTest[0];
        }
      });
  }

  getAvailableTest() {
    this._testerTestSer.approvedTest().subscribe((res) => {
      // let isApproved = false;
      // // let isApproved = true;
      // if (res.data.length >= 1) {
      //   isApproved = res.data[0].is_approved;
      //   this.isVideoTestApproved = res.data[0].is_approved;
      // }
      let isApproved = false;
      if (res.data.length >= 1) {
        res.data.every((item)=>{
          if(item.status === "APPROVED"){
            isApproved = true;
            this.isVideoTestApproved = true;
            return false;
          } else {
            isApproved = false;
            this.isVideoTestApproved = false;
            return true;
          }
        });
      }
      if (isApproved) {
        this.dummyTestShow = true;
        this.renderDummyTextMsg = 'Available tests';
        this.testerGetData = this._testerTestSer
          .getTesterTest()
          .subscribe((availableTestRes) => {
            this.availableTestCount = availableTestRes.data.length;
            if (availableTestRes.data.length >= 1) {
              this.ShowAvlTest = true;
            } else {
              this.ShowAvlTest = false;
              this.noTestAvailableMsg = `We have no available tests for you. Please come back later or check
              your email for new tests.`;
            }
            if (!availableTestRes.data) {
              return;
            } else {
              this.availableTest = availableTestRes.data.sort((a, b) => {
                return (
                  +new Date(b['creation_date']) - +new Date(a['creation_date'])
                );
              });
            }
          });
      } else {
        this.dummyTestShow = false;
        this.renderDummyTextMsg = 'Available Trial tests';
      }
    });
  }

  getCompletedTestList() {
    this._testerTestSer.getCompleteTest().subscribe((res) => {
      if (res.data.length === 0) {
        this.completedTestShow = false;
      } else {
        this.completedTestShow = true;
        this.completedTestCount = res.data.length;
      }

      // if(res.data.length>=0){
      // this.notestComplete = true
      this.completedList = res.data.sort((a, b) => {
        return +new Date(b['date_of_tested']) - +new Date(a['date_of_tested']);
      });

      // }else{
      //   this.notestComplete = false

      // }
    });
  }

  checkSupportedDevice(){
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      // true for mobile device
      if(!(/(CrOS)/.test(navigator.userAgent))){ // exclude chrome OS
        if(this.ShowAvlTest == false){
          this._customPopupBoxService.showPopup("mobile-device-blocked");
          this.takeTestBtnActive = false;
        }
      } 
    } else{
      this.takeTestBtnActive = true;
    }
  }

  validateDeviceTypeAndTestType(){
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const userAgent = navigator.userAgent;
    console.log('navigator.userAgent', navigator.userAgent);

    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)){
      // true for mobile device
      if(!(/(CrOS)/.test(userAgent))){ // exclude chrome OS
        if(screenWidth >= 600){
          if(/Mobile/i.test(userAgent)){
            this.currentDeviceType = "mobile"
          } else{
            this.currentDeviceType = "tablet"
          }
        } else{
          this.currentDeviceType = "mobile"
        } 
      } else { 
        this.currentDeviceType = "desktop"
      }
    } else{
      this.currentDeviceType = "desktop"
    }
  }

  openDeviceNotSupportedPopup(){
    this._customPopupBoxService.showPopup("mobile-device-blocked");
  }

  checkTestType(testType){
    if(testType.toLowerCase() === "phone"){
      if("mobile" === this.currentDeviceType){
        return true;
      } else{
        return false;
      }
    } else{
      if(testType.toLowerCase() === this.currentDeviceType){
        return true;
      } else {
        return false;
      }
    }
  }

  closeTesterPopup(){
    this.showTesterPopup = false;
  }


  callAvailableTestBtn(testDetails){
    let jsonData = testDetails;
    jsonData["from_state"] = "Invited";
    jsonData["to_state"] = "Accepted";
    this._testerTestSer.takeTest(jsonData).subscribe((res)=>{
        if(res['data'] == true){
          let takeTestBtnRef = document.getElementById("available-take-test-btn") as HTMLButtonElement;
          takeTestBtnRef.click();
        } else if(res['data'] == false){
          this.showTesterPopup = true;
          this.testerPopupContentType = "actual-test-already-taken";
        }
    });



  }


  ngOnDestroy() {
    // this.testerGetData.unsubscribe();
    this.destroyUploadApi.unsubscribe();
  }
}
