import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {TokenParms} from '../models/token-parms';
import {Router} from '@angular/router';
import {AuthService} from '../../shared/services/auth.service';


@Component({
  selector: 'app-api-call',
  templateUrl: './api-call.component.html',
  styleUrls: ['./api-call.component.css']
})
export class ApiCallComponent implements OnInit {
  @ViewChild('page', {static: false}) page: ElementRef;
  token_id: TokenParms;
  error1;

  constructor(private router: Router, private renderer: Renderer2, private apiSer: AuthService) {
  }

  ngOnInit() {
  }

  logout() {
    this.apiSer.logout()
      .subscribe(res => {
        localStorage.removeItem('token_id');
        localStorage.clear();
        this.router.navigate(['/']),
          this.error1 = res;
        error => console.log('this was an err', error);

      });

  }

}
