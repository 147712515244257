import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ApiCallComponent} from './api-call/api-call.component';
import {RegisterComponent} from './auth/register/register.component';
import {LoginComponent} from './auth/login/login.component';
import {AuthGuard} from '../auth.guard';
import {PasswordStrengthMeterModule} from 'angular-password-strength-meter';
import {PasswordStrengthComponent} from './password-strength/password-strength.component';
import {SharedModule} from '../shared/shared.module';
import {CoreRoutingModule} from './../core/core-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Ng5SliderModule} from 'ng5-slider';
import {SimpleNotificationsModule} from 'angular2-notifications';
import {NgxSpinnerModule} from 'ngx-spinner';
import {TesterModule} from './../modules/tester/tester.module';
import {LoginTesterComponent} from './auth/login-tester/login-tester.component';
import { ActivateEmailLoginComponent } from './auth/activate-email-login/activate-email-login.component';

@NgModule({
  declarations: [
    ApiCallComponent,
    LoginComponent,
    RegisterComponent,
    PasswordStrengthComponent,
    LoginTesterComponent,
    ActivateEmailLoginComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
  ReactiveFormsModule,
    RouterModule,
    PasswordStrengthMeterModule,
    Ng5SliderModule,
    SharedModule,
    BrowserAnimationsModule,
    SimpleNotificationsModule.forRoot(),
    CoreRoutingModule,
    TesterModule,
    NgxSpinnerModule,
    TesterModule,
  ],
  providers: [AuthGuard],
})
export class CoreModule {
}
