import { Component, OnInit } from '@angular/core';
import { CustomPopupBoxService } from '../services/custom-popup-box.service';

@Component({
  selector: 'app-custom-popup-box',
  templateUrl: './custom-popup-box.component.html',
  styleUrls: ['./custom-popup-box.component.css']
})
export class CustomPopupBoxComponent implements OnInit {

  showContentType: string = '';

  constructor(public customPopupService: CustomPopupBoxService) { }

  ngOnInit() {

    this.customPopupService.contentType$.subscribe((value)=>{
      this.showContentType = value;
    })
    
  }


  closePoup(){
    this.customPopupService.dismissPopup();
  }


}
