import {AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild,} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {RecordingOverlayService} from 'src/app/shared/services/recording-overlay.service';
import {SocketioService} from 'src/app/socketio.service';
import {Subscription} from 'rxjs';
import {NavigationStart, Router} from '@angular/router';
import {TesterTestService} from 'src/app/shared/services/tester-test.service';
import {CompleteTesterProfileService} from 'src/app/shared/services/complete-tester-profile.service';
import { ToastMsgService } from 'src/app/shared/services/toast-msg.service';

// import { DialogService } from './dialog.service';
@Component({
  selector: 'app-recording-overlay',
  templateUrl: './recording-overlay.component.html',
  styleUrls: ['./recording-overlay.component.css'],
})
export class RecordingOverlayComponent
  implements OnInit, AfterViewInit, OnDestroy {
  onOk: EventEmitter<any> = new EventEmitter();
  onCancel: EventEmitter<any> = new EventEmitter();
  @ViewChild('recordBtn', {static: false}) startButton: ElementRef;
  @ViewChild('stopBtn', {static: false}) stopButton: ElementRef;
  @Input() avlTest: any;
  @Output() startRecordingEvent: any = new EventEmitter<any>();
  public backdrop: HTMLElement;
  // backdrop:boolean = true
  style: any;
  dataValue: number = 0;
  testIntroduction: any;
  taskDetails: any;
  taskSteps: number = 0;
  microphonePermissionStatus: string = "";
  recordingFlow = [
    {
      title: 'Setup your environment',
      description: 'For this test you need the following:',
      buttonText: 'Got it.',
    },
    {
      title: 'Give feedback like a pro!',
      description: 'Follow these instructions while testing:',
      buttonText: 'Got it.',
    },
    {
      title: 'Permissions Required',
      description: 'These permissions are required to start the test:',
      buttonText: 'Proceed',
    },
    {
      title: 'Task Details',
      description: `Remember to read each task out loud while recording.`,
      buttonText: 'Start recording',
    },
    {
      title: 'Task Details',
      description: '',
      buttonText: `Start Task ${this.taskSteps}`,
    },
  ];

  dataCOntent = this.recordingFlow[0];
  @ViewChild('closeBtn', {static: false}) closeBtn: ElementRef;

  // OverlaySTatus

  isBeforeYouStart: boolean = false;
  isPrepraedForTesting: boolean = false;
  isPermissionRequired: boolean = false;
  isScrMicPermission: boolean = false;
  isPermissionApproved: boolean = false;
  recordinTimerCount: any;
  taskdetailsdurationwise: any = {};
  startTimeTask: any;
  endTimeTask: any;
  customModelUnsubscribe: Subscription;
  stopBtnUnsubscribe: Subscription;
  subscription: Subscription;
  isVideoTestApproved: any;
  pop: Window;
  testerProfileDetails: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private recordingSer: RecordingOverlayService,
    private socketioService: SocketioService,
    private router: Router,
    private _testerTestSer: TesterTestService,
    private _completeProfileServices: CompleteTesterProfileService,
    public toastMsgService: ToastMsgService,
  ) {
    // console.log("dial clicked value");
    router.events.subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        // Perform actions

        this.cancelClicked();
        this.reportTest();
      }
    });
  }

  ngOnInit() {
    this.customModelUnsubscribe = this.recordingSer.customObservable.subscribe(
      (res) => {
        console.log('dial clicked value value');

        if (res) {
          this.show();
        }
      }
    );
    setInterval(this.checkPageFocus, 300);

  }

  ngAfterViewInit() {
    this.recordingSer.recordingTimerObserable.subscribe((timer) => {
      if (timer) {
        this.recordinTimerCount = timer;
        this.handleBrowserTimerBtn(true);
      }
    });

    this.taskdetailsdurationwise = {
      Task: [],
    };
  }

  okClicked() {
    this.onOk.emit();
  }

  checkTesterApproved() {
    this._testerTestSer.approvedTest().subscribe((res) => {
      this.isVideoTestApproved = res.data[0].is_approved;
    });
  }

  cancelClicked(isCancel?) {
    this.onCancel.emit();
    this.closeBtn.nativeElement.click();
    // this.recordingSer.callUploadTest(this.avlTest, null);
    this.recordingSer.changeTaskOverlayCount.next(0);
    if (isCancel == 'stopREcording') {
      this.reportTest('stopREcording');
    }
    this.hide();
    if (isCancel) {
      this.dataValue = 0;
    }
    // this.backdrop = false
    // this.hideBackdrop();
  }

  

  refreshPage(){
    window.location.reload();
  }

  show() {
    // this.backdrop = true;
    this.checkTesterApproved();
    this.document.body.classList.add('modal-open');
    this.style = {display: 'block'};
    this.showBackdrop();

    if (this.dataValue == 3) {
      this.getProfileDeatils();
      this.taskDetails = this.avlTest.test_instructions[0];
      this.testIntroduction = this.avlTest.test_introduction;
      this.subscription.unsubscribe();
    }
  }

  hide() {
    this.closeBtn.nativeElement.click();
    this.document.body.classList.remove('modal-open');
    this.style = {display: 'none'};

    this.hideBackdrop();
    // this.dataValue = 0;
  }

  showBackdrop() {
    this.backdrop = this.document.createElement('div');
    this.backdrop.classList.add('modal-backdrop');
    this.backdrop.classList.add('show');
    this.document.body.appendChild(this.backdrop);
  }

  hideBackdrop() {
    if (this.backdrop != undefined) {
      this.backdrop.remove();
    }
  }

  changeTestStateInvited(){
    
    let jsonData = this.avlTest;
    jsonData["from_state"] = "Accepted";
    jsonData["to_state"] = "Invited";
    
    this._testerTestSer.approvedTest().subscribe((res)=>{
      if(res.data[0].is_approved){
        this._testerTestSer.takeTest(jsonData).subscribe();
      }
    });
  
  }

  changeTestStateAccepted(){
    
    let jsonData = this.avlTest;
    jsonData["from_state"] = "Invited";
    jsonData["to_state"] = "Accepted";
    
    this._testerTestSer.approvedTest().subscribe((res)=>{
      if(res.data[0].is_approved){
        this._testerTestSer.takeTest(jsonData).subscribe();
      }
    });
  
  }

  handleRecordingOverlay(data: any, dataValue) {
    this.dataValue = this.dataValue + 1;
    this.dataCOntent = this.recordingFlow[this.dataValue];
    if (this.dataValue <= 4) {
      switch (this.dataValue) {
        case 1: {
          this.isBeforeYouStart = true;
          break;
        }
        case 2: {
          this.isPrepraedForTesting = true;
          // check for microphone permission
          let that = this;
          navigator.permissions.query({ name: 'microphone' as PermissionName })
          .then(function(permissionStatus) {
            that.microphonePermissionStatus = permissionStatus.state
          });
          break;
        }
        case 3: {
          let that = this;
          // check for microphone permission
          if(this.microphonePermissionStatus === "granted"){
            this.isPermissionRequired = true;
            this.recordingSer.callRecordBntRefMehtod(this.startButton);
            this.handlePermission();
            this.cancelClicked(undefined);
          }
          else if(this.microphonePermissionStatus === "prompt"){
            this.isPermissionRequired = true;
            this.recordingSer.callRecordBntRefMehtod(this.startButton);
            this.handlePermission();
            this.cancelClicked(undefined);
          }
          else{
            this.toastMsgService.showMsg("failed", "Enable microphone permission", 5000);
            this.cancelClicked();
          }         
          break;
        }
        case 4: {
          this.isScrMicPermission = true;
          this.startRecordingEvent.emit(this.startButton);
          this.startTimeTask = this.recordinTimerCount;

          this.taskSteps = 2;
          this.taskDetails = this.avlTest.test_instructions[0];
          this.pop = window.open(this.avlTest.start_url, '_blank');
          break;
        }
        default: {
          break;
        }
      }
    } else {
      this.startButton.nativeElement.disabled = true;
      if (this.taskSteps <= this.avlTest.test_instructions.length) {
        if (this.taskSteps == 1) {

          // this.pop = window.open(this.avlTest.start_url, '_blank');
          // window.open(this.avlTest.start_url, 'MDN', 'width=640,height=320,left=150,top=150');

        } else {
          // this.checkedIsFocused(true);
          if (this.pop) {
            // this.pop.blur();
            // this.pop.focus();
          } else {
            // this.pop = window.open(this.avlTest.start_url, '_blank');
          }
        }
      }
      
      if (
        this.dataValue >= 5 &&
        this.taskSteps < this.avlTest.test_instructions.length
      ) {
        this.taskDetails = this.avlTest.test_instructions[this.taskSteps - 1];
        this.taskSteps++;
        this.startButton.nativeElement.disabled = true;

        setTimeout(() => {
          this.startButton.nativeElement.disabled = false;
        }, 3000);
        this.calculateTaskTimeStamp();
      } else {
        this.taskDetails = this.avlTest.test_instructions[this.taskSteps - 1];
        this.taskSteps = this.taskSteps + 1;
        this.calculateTaskTimeStamp();
        this.handleBrowserTimerBtn(true);

        if (
          this.taskdetailsdurationwise.Task.length ==
          this.avlTest.test_instructions.length
        ) {
          this.handleUploadTest();
          this.cancelClicked(undefined);
          this.hide();
        }
      }
    }
  }

  // checkedIsFocused(doFocus){
  //   // let myWindow=window.open('','mywindow');
  //   let myWindow=  window.open(this.avlTest.start_url,'mywindow');
  //   // myWindow.document.write("<p>This is 'myWindow'</p>");
  //   if(doFocus)
  //       myWindow.focus();
  // }

  checkPageFocus() {
    let body = document.querySelector('body');
    let log = document.getElementById('log');

    if (document.hasFocus()) {
      // log.textContent = 'This document has the focus.';
      // body.style.background = '#fff';
    } else {
      // log.textContent = 'This document does not have the focus.';
      // body.style.background = '#ccc';
    }
  }

  calculateTaskTimeStamp() {
    switch (this.taskSteps) {
      case 2: {
        this.startTimeTask = '0:00';
        this.endTimeTask = this.recordinTimerCount;
        break;
      }
      case 3: {
        this.endTimeTask = this.recordinTimerCount;
        this.createObjetForTimeStamp(
          this.startTimeTask,
          this.endTimeTask,
          this.avlTest.test_instructions[0]
        );
        break;
      }
      case 4: {
        this.startTimeTask = this.endTimeTask;
        this.endTimeTask = this.recordinTimerCount;
        this.createObjetForTimeStamp(
          this.startTimeTask,
          this.endTimeTask,
          this.avlTest.test_instructions[1]
        );
        break;
      }
      case 5: {
        this.startTimeTask = this.endTimeTask;
        this.endTimeTask = this.recordinTimerCount;
        this.createObjetForTimeStamp(
          this.startTimeTask,
          this.endTimeTask,
          this.avlTest.test_instructions[2]
        );
        break;
      }
      case 6: {
        this.startTimeTask = this.endTimeTask;
        this.endTimeTask = this.recordinTimerCount;
        this.createObjetForTimeStamp(
          this.startTimeTask,
          this.endTimeTask,
          this.avlTest.test_instructions[3]
        );
        break;
      }
      default: {
        this.startTimeTask = this.endTimeTask;
        this.endTimeTask = this.recordinTimerCount;
        this.createObjetForTimeStamp(
          this.startTimeTask,
          this.endTimeTask,
          this.avlTest.test_instructions[4]
        );

        break;
      }
    }
  }

  createObjetForTimeStamp(startTask, endTask, details) {
    let taskSlap = {
      startTime: startTask,
      endTime: endTask,
      task: details,
    };
    this.taskdetailsdurationwise.Task.push(taskSlap);
  }

  handleUploadTest() {

    this.dataValue = 0;
    this.taskSteps = 1;
    this.recordingSer.callUploadTest(
      this.avlTest,
      this.taskdetailsdurationwise.Task
    );
    this.taskdetailsdurationwise = {
      Task: [],
    };
  }

  reportTest(cancelValue?) {
    // window.open(`https://userpeek.com/submit-a-test-for-review/?testid= ${this.avlTest.id}&first=Peter&last=Smith&email=userpeek@gmail.com`, "_blank");
    this.recordingSer.callUploadTest(this.avlTest, null);
    this.dataValue = 0;
    this.taskSteps = 1;
    window.document.title = 'userpeek';
    if (!cancelValue) {
      this.cancelClicked(undefined);
    }
    this.hide();
  }

  handleBrowserTimerBtn(startRecording) {
    if (startRecording) {
      document.title = this.recordinTimerCount;
    }
  }

  handleRecordingEvent(value: string) {
  }

  handlePermission() {
    this.socketioService.sendMessage('permission');
    this.subscription = this.recordingSer.stopBtnObserable.subscribe(
      (permissionAccess) => {
        if (permissionAccess === 'recordStart' && !permissionAccess.message) {
          this.dataValue = 3;
          this.isPermissionApproved = true;
          this.show();
        } else {
          this.cancelClicked(true);
        }
        // this.hideBackdrop();
      }
    );
  }

  getProfileDeatils() {
    this._completeProfileServices.getProfile().subscribe((res) => {
      this.testerProfileDetails = res.data[0];
    });
  }


  ngOnDestroy() {
    this.customModelUnsubscribe.unsubscribe();
    //  this.stopBtnUnsubscribe.unsubscribe()
  }
}
