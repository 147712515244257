import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {environment} from 'src/environments/environment';
import {AuthService} from './auth.service';
import {Markers} from '../../core/models/video-marker';
import {Rating} from 'src/app/core/models/rating';
import {AddDescription} from 'src/app/core/models/addDescription';

@Injectable({
  providedIn: 'root'
})
export class TesterTestedListService {

  player: any;
  marker = new Subject<[]>();
  private _testVideoListId = new Subject<string>();
  testedId$ = this._testVideoListId.asObservable();
  private _testVideoListProjectName = new Subject<string>();
  testedIdProjectName$ = this._testVideoListProjectName.asObservable();

  constructor(
    private http: HttpClient,
    private _authServ: AuthService,
  ) {
  }

  sendTestId(testId: any) {
    this._testVideoListId.next(testId);
  }

  sendTestIdProjectName(testProjectName: any) {
    this._testVideoListId.next(testProjectName);
  }

  getTestedTestVideoList(): Observable<any> {

    const options = {
      headers: new HttpHeaders({
        Authorization: 'Token ' + this._authServ.getToken()
      }),
      params: new HttpParams(),
      reportProgress: false
    };
    return this.http.get(environment.apiUrl + 'test/video_list/', options);
  }

  getTestedTestVideoListId(id): Observable<any> {

    const options = {
      headers: new HttpHeaders({
        Authorization: 'Token ' + this._authServ.getToken()
      }),
      params: new HttpParams(),
      reportProgress: false
    };
    return this.http.get(environment.apiUrl + 'test/video_list/' + id, options);
  }

  addMarker(marker: Markers): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Token ' + this._authServ.getToken()
      }),
      params: new HttpParams(),
      reportProgress: false
    };

    return this.http.post(environment.apiUrl + 'test/bookmarks/', marker, options);
  }

  getMarkerList(test) {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Token ' + this._authServ.getToken()
      }),
      params: new HttpParams()
        .set('test', test),
      reportProgress: false
    };
    return this.http.get(environment.apiUrl + 'test/bookmarks/', options);
  }

  updateMarker(id: any, marker: Markers): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Token ' + this._authServ.getToken()
      }),
      params: new HttpParams()
        .set('id', id),
      reportProgress: false
    };

    return this.http.put(environment.apiUrl + 'test/bookmarks/', marker, options);
  }

  deleteMarker(id) {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Token ' + this._authServ.getToken()
      }),
      params: new HttpParams()
        .set('id', id),
      reportProgress: false
    };
    return this.http.delete(environment.apiUrl + 'test/bookmarks/', options);
  }

  addRating(rate: Rating): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Token ' + this._authServ.getToken()
      }),
      params: new HttpParams(),
      reportProgress: false
    };
    return this.http.post(environment.apiUrl + 'test/rating/', rate, options);
  }


  getRating(test) {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Token ' + this._authServ.getToken()
      }),
      params: new HttpParams()
        .set('test', test),
      reportProgress: false
    };
    return this.http.get(environment.apiUrl + 'test/rating/', options);
  }

  addDescription(addDesc: AddDescription): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Token ' + this._authServ.getToken()
      }),
      params: new HttpParams(),
      reportProgress: false
    };
    return this.http.post(environment.apiUrl + 'test/test_description/', addDesc, options);
  }

  getDescription(test): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Token ' + this._authServ.getToken()
      }),
      params: new HttpParams()
        .set('test', test),
      reportProgress: false
    };
    return this.http.get(environment.apiUrl + 'test/test_description/', options);
  }


}
