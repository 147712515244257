import { Injectable } from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModelBox2Service {
  isModelShow = new BehaviorSubject<boolean>(false);
  modelBoxHeader: string;
  modelBoxBody: string;
  componentStatus: string;
  isGettingError: boolean;

  constructor(
    private toastr: ToastrService,
    private _modelBoxService: ModelBox2Service
  ) {
  }

  showSuccessToster(tosterMsg?, status?) {
    this.toastr.success(tosterMsg, status);
  }

  showErrorToster(tosterMsg?, status?) {
    this.toastr.error(tosterMsg, status);
  }

  showModelBox(isSuccess, modelBody?, modelBoxHeader?, modelBoxBody?, componentStatus?) {
    // this.destroyModelCurrentValue();
    if (isSuccess) {
      this.modelBoxHeader = modelBoxHeader;
      this.modelBoxBody = modelBoxBody ? modelBody + modelBoxBody : modelBody;
      this.componentStatus = componentStatus;
      this.isGettingError = false;
      this.isModelShow.next(true);
    } else {
      this.modelBoxHeader = modelBoxHeader;
      this.modelBoxBody = modelBoxBody ? modelBody + modelBoxBody : modelBody;
      this.componentStatus = componentStatus;
      this.isGettingError = true;
      this.isModelShow.next(true);
      // this.isGettingError = true;
      // this.modelBoxBody = modelBody;
      // this.componentStatus = '';
      // this.isModelShow.next(true);
    }
  }

  destroyModelCurrentValue() {
    // this._modelBoxService.isModelShow = null;
    this._modelBoxService.modelBoxHeader = null;
    this._modelBoxService.modelBoxBody = null;
    this._modelBoxService.isGettingError = null;
    this._modelBoxService.componentStatus = null;
  }

}
