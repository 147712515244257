import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import Cropper from 'cropperjs';

@Component({
  selector: 'image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.css']
})
export class ImageCroppperComponent implements OnInit {

  @ViewChild('image', {static: false})
  public imageElement: ElementRef;

  @Input('src')
  public imageSource: string;

  @Output() onSave = new EventEmitter<any>();

  public imageDestination: string;
  private cropper: any;

  public constructor() {
    this.imageDestination = '';
  }

  public ngAfterViewInit() {
    this.cropper = new Cropper(this.imageElement.nativeElement, {
      zoomable: false,
      scalable: false,
      aspectRatio: 1,
      crop: () => {
        const canvas = this.cropper.getCroppedCanvas();
        this.imageDestination = canvas.toDataURL('image/png');
      }
    });
  }

  public ngOnInit() {
  }

  crop() {
    this.onSave.emit({status: true, image: this.imageDestination});
  }

  cancel() {
    this.onSave.emit({status: false});
  }

}
