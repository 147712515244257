import { Component, OnInit } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import { CompleteTesterProfileService } from '../shared/services/complete-tester-profile.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.css']
})
export class HeaderOneComponent implements OnInit {

  logoutTempError1: any;
  profileImgUrl: any;


  constructor(
    private authSer: AuthService,
    private _router: Router,
    public toastr: ToastrService,
    private _completeProfileServices: CompleteTesterProfileService,
  ) { }

  ngOnInit() {
    this._completeProfileServices.getProfile().subscribe((res)=>{
      if(res.data[0].image == ""){
        this.profileImgUrl = "assets/images/icons/profile-user.png";
      } else {
        this.profileImgUrl = `${environment.apiUrl}${res.data[0].image}`;
      }
    })
  }



  logout() {
    this.authSer.logout().subscribe((res) => {
      localStorage.removeItem('token_id');
      localStorage.clear();
      this.showSuccessToster();
      this._router.navigate(['/']), (this.logoutTempError1 = res);
    });
  }

  showSuccessToster() {
    this.toastr.success('Logout successful!', 'Success!');
  }
}
