import { Component, OnInit } from '@angular/core';
import { ToastMsgService } from 'src/app/shared/services/toast-msg.service';

@Component({
  selector: 'app-toast-msg',
  templateUrl: './toast-msg.component.html',
  styleUrls: ['./toast-msg.component.css']
})
export class ToastMsgComponent implements OnInit {

  // showToast: boolean = false;
  showToast: boolean;
  iconType: boolean = true;
  toastText: any;

  constructor( public toastController: ToastMsgService) { }

  ngOnInit() {
  }

  dismiss(){
    this.toastController.dismissToast();
  }

}
