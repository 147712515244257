import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {TransformDirective} from './transform.directive';
import {HeaderComponent} from './shared/header/header.component';
import {CoreModule} from './core/core.module';
import {AuthGuard} from './auth.guard';
import {SharedModule} from './shared/shared.module';
import {TesterModule} from './modules/tester/tester.module';
import {BnNgIdleService} from 'bn-ng-idle';

import {HttpInterceptorService} from './shared/http-interceptor.service';
import {SocketioService} from './socketio.service';
import {NgxPaginationModule} from 'ngx-pagination';
import {ToastrModule} from 'ngx-toastr';
import { CustomCssComponent } from './custom-css/custom-css.component';
import { HeaderOneComponent } from './header-one/header-one.component';
import { NewTestPageComponent } from './new-test-page/new-test-page.component';
import { ToastMsgComponent } from './modules/toast-msg/toast-msg.component';

@NgModule({
  declarations: [AppComponent, TransformDirective, HeaderComponent, CustomCssComponent, HeaderOneComponent, NewTestPageComponent, ToastMsgComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    CoreModule,
    SharedModule,
    TesterModule,
    NgxPaginationModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
    }),
    BrowserAnimationsModule,
  ],
  providers: [
    AuthGuard,
    BnNgIdleService,
    SocketioService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    //  {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: HttpInterceptorService,
    //   multi: true
    // }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
