import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {AuthService} from 'src/app/shared/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login-tester',
  templateUrl: './login-tester.component.html',
  styleUrls: ['./login-tester.component.css']
})
export class LoginTesterComponent implements OnInit {

  errorMsg: any;
  responseError: string;
  Error: boolean;

  constructor(
    private _authServices: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    let queryParams = new URLSearchParams(window.location.search);
    let adminToken = queryParams.get('adminToken');
    localStorage.setItem('token_id', adminToken);
    let email = queryParams.get('email');

    if (adminToken && email) {
      // Call Api START
      this._authServices.loginasAdmin(email).subscribe((employee) => {
        var succ = JSON.stringify(employee);
        localStorage.setItem('token_id', employee['key']);
        localStorage.setItem('data', employee['username']);
        localStorage.setItem('usertype', employee['usertype']);
        localStorage.setItem('user_id', employee['user_id']);

        var status = employee['status'];
        var usertype = employee['usertype'];
        this.responseError = employee['message'];

        if (status == 200 && usertype == 'tester') {
          // localStorage.setItem("token_id", employee["key"]);

          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: 'success',
            title: 'Login successful',
          });
          this.router.navigate(['dashboard']);
        } else if (status == 401 || status == 400) {
          this.responseError = this.responseError;
          // this.responseError = "No account using these credentials found. Please check email and password";
        } else {
          this.Error = true;
          this.responseError =
            'No account using these credentials found. Please check email and password';
          // this.massage = "this is not a client login";
          // localStorage.setItem("token_id", employee["key"]);
          // this.router.navigate(["dashboard"]);
          // this.errorMsg = succ.Message;

        }

        // }else{
        //   this.notUser = "This account not for  " + localStorage.getItem('role') +" login" + " or Please check email and password"

        // }

        // }

        (err) => {

          this.errorMsg = err;
        };
      });

      // let employee = {
      //   "key": "cb4b31da2a29cbf65ae265f75e1817743342c85b",
      //   "usertype": "client",
      //   "username": "chargeover3@yopmail.com",
      //   "profile_percentage": 30,
      //   "trial_test": false,
      //   "user_id": "213",
      //   "message": "success",
      //   "status": 200
      // }

      // localStorage.setItem("data", employee.username);
      // localStorage.setItem("usertype", employee.usertype);
      // localStorage.setItem("user_id", employee.user_id);
      // localStorage.setItem("token_id", employee["key"]);

      // this.router.navigate(["dashboard"]);

      // Call Api END
    } else {
      this.router.navigate(['login']);
    }
  }

  // userName: any;
  // loginForm: FormGroup;
  // regForm: FormGroup;
  // massage: string;
  // Error = false;
  // resetURL;
  // urlReset: any;
  // errorMsg: string;
  // responseError: any;
  // submitted: boolean = false;
  // urlType: string;
  // testerSignup

  // constructor(
  //   private _authServices: AuthService,
  //   private formbuilder: FormBuilder,
  //   private router: Router,
  //   private route: ActivatedRoute,
  //   private renderer: Renderer2,
  //   private _dashboardClient: DashboardClientService
  // ) {}

  // ngOnInit() {
  //   this.route.url.subscribe(url =>

  //     this.urlType = url[0].path
  //     );

  //     if(this.urlType === "client"){
  //       this.testerSignup = true
  //     }
  //     else{
  //       this.testerSignup = false
  //     }

  //     localStorage.setItem("role",this.urlType)

  //   this.setFormState();
  //   this.urlReset = this._authServices.url;
  // }
  // // convenience getter for easy access to form fields
  // get f() {
  //   return this.loginForm.controls;
  // }

  // setFormState(): void {
  //   this.loginForm = this.formbuilder.group({
  //     // username: ['piyushrai2', [Validators.required]],
  //     email: [
  //       "",
  //       [
  //         Validators.required,
  //         Validators.email,
  //         Validators.pattern("^[A-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
  //       ]
  //     ],
  //     password: ["", [Validators.required]]
  //   });
  // }

  // onLogin() {
  //   this.submitted = true;
  //   if (this.loginForm.invalid) {
  //     return;
  //   }
  //   this.submitted = true;
  //   let login = this.loginForm.value;
  //   this.login(login);
  // }
  // // logi Api call

  // login(login: TokenParms) {
  //   this._authServices.login(login).subscribe(
  //     employee => {
  //       var succ = employee;
  //       this._dashboardClient.setData(employee);

  //       localStorage.setItem("data", employee.username);
  //       localStorage.setItem("usertype", employee.usertype);
  //       localStorage.setItem("user_id",employee.user_id)
  //
  //       var status = employee["status"];
  //       var usertype = employee["usertype"];
  //       this.responseError = employee["message"];

  //       if (status == 200 && usertype == "client") {
  //         this.loginForm.reset();
  //         localStorage.setItem("token_id", employee["key"]);
  //         const Toast = Swal.mixin({
  //           toast: true,
  //           position: 'top-end',
  //           showConfirmButton: false,
  //           timer: 3000,
  //           timerProgressBar: true,
  //           onOpen: (toast) => {
  //             toast.addEventListener('mouseenter', Swal.stopTimer)
  //             toast.addEventListener('mouseleave', Swal.resumeTimer)
  //           }

  //         })

  //         Toast.fire({
  //           icon: 'success',
  //           title: 'Login successfully'
  //         })
  //         this.router.navigate(["dashboard"]);
  //       } else if (status == 401) {
  //         this.responseError ="Email or Password is Incorrect"
  //       } else {
  //         this.Error = true;
  //         this.massage = "this is not a client login";
  //         localStorage.setItem("token_id", employee["key"]);

  //         this.router.navigate(["dashboardTester"]);

  //         this.errorMsg = succ.Message;
  //       }

  //     },
  //     err => {

  //       this.errorMsg = err;
  //     }
  //   );
  // }

  // //  Reset Password

  // resetpass() {
  //   this._authServices.forgetPassword().subscribe(res => {
  //     this.resetURL = res;
//  );
  //     this.router.navigate(["res"]);
  //   });
  // }
}
