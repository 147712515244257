import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {AuthService} from './auth.service';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {appApiResources} from './api.constant';

@Injectable({
  providedIn: 'root'
})
export class TesterTestService {

  constructor(private _authServ: AuthService, private http: HttpClient) {
  }

  getTesterTest(): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Token ' + this._authServ.getToken()
      }),
      params: new HttpParams(),
      reportProgress: false
    };

    return this.http.get(environment.apiUrl + 'admin/available_test/', options);
    // return this.http.get(environment.apiUrl + 'test/get_pending_test/', options);

  }

  getDummyTest(): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Token ' + this._authServ.getToken()
      }),
      params: new HttpParams(),
      reportProgress: false
    };

    return this.http.get(appApiResources.getAdminDummyTest, options);

  }

  getCompleteTest(): Observable<any> {
    return this.http.get(appApiResources.getCompletedTest);
  }

  getTotalEarning(): Observable<any> {
    return this.http.get(appApiResources.getTotalEarning);
  }

  approvedTest(): Observable<any> {
    return this.http.get(appApiResources.getApproveDummyTest);
  }


  takeTest(jsonData) {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Token ' + this._authServ.getToken(),
      }),
      params: new HttpParams(),
      reportProgress: false,
    };

    return this.http.post(
      appApiResources.takeATest,
      jsonData,
      options
    );
  }

  checkTestAvailability(jsonData) {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Token ' + this._authServ.getToken(),
      }),
      params: new HttpParams(),
      reportProgress: false,
    };

    return this.http.post(appApiResources.checkTestAvailability,{"test_id": jsonData},options);
  }

  
}
