import {Component, NgZone} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {BnNgIdleService} from 'bn-ng-idle';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  login: boolean = false;
  showHead: boolean;

  constructor(
    private zone: NgZone,
    private router: Router,
    private bnIdle: BnNgIdleService
  ) {
    this.bnIdle.startWatching(20000).subscribe(res => {
      if (res) {
        this.router.navigate(['/login']);
        // alert("session exipred");
        Swal.fire({
          title: 'Your session has expired!',
          showClass: {
            popup: 'animated fadeInDown faster'
          },
          hideClass: {
            popup: 'animated fadeOutUp faster'
          }
        })
        ;
        localStorage.removeItem('token_id');
        localStorage.clear();
      }
    });
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        console.log("URL ::: ",(event['url']));
        let conditions = /^\/$/.test(event['url']) || /\/login/.test(event['url']) ||  /\/register/.test(event['url']) || /\/activate.*$/i.test(event['url']);
        console.log("REGEX :::  ",conditions);
        if (conditions) {
          console.log("isnide if block ");
          this.showHead = false;
        } else {
          console.log("isnide else block ");
          this.showHead = true;
        }
      }
    });
  }
}
