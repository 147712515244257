import {Directive, ElementRef, HostListener, Renderer2} from '@angular/core';

@Directive({
  selector: '[clickOutside]',
})
export class ToggleDirective {

  // constructor(private _elementRef: ElementRef) {
  // }

  // @Output()
  // public clickOutside = new EventEmitter<MouseEvent>();

  // @HostListener('document:click', ['$event', '$event.target'])
  // public onClick(event: MouseEvent, targetElement: HTMLElement): void {
  //     if (!targetElement) {
  //         return;
  //     }

  //     const clickedInside = this._elementRef.nativeElement.contains(targetElement);
  //     if (!clickedInside) {
  //         this.clickOutside.emit(event);
  //     }
  // }

  constructor(private _ren: Renderer2, private _el: ElementRef) {
  }

  @HostListener('click') onClick() {
    this._ren.addClass(this._el.nativeElement, 'bg-red');
    setTimeout(() => {
      this._ren.removeClass(this._el.nativeElement, 'bg-red');
    }, 2000);
  }
}
