import {environment} from 'src/environments/environment';

const baseUrl = environment.apiUrl;

function createUrl(actionName: string): string {
  return `${baseUrl}${actionName}`;
}

export const appApiResources = {
  login: createUrl('user/login/'),
  loginAsAdmin: createUrl('admin/admin_view_as_user/'),

  checkEmailExist: createUrl('profile/is_email_exists/'),

  passwordReset: createUrl('password_reset/'),
  userLogout: createUrl('user/logout/'),
  testerProfileCreate: createUrl('profile/create_tester_profile/'),
  createrTesterProfile: createUrl('profile/tester_profile/'),
  getCountryDeviceList: createUrl('profile/get_lang_country_devices/'),
  deleteProfileImage: createUrl('profile/delete_image/'),

  getAdminDummyTest: createUrl('admin/dummy_test/'),

  getCompletedTest: createUrl('test/get_completed_test/'),

  getTotalEarning: createUrl('test/test_income/'),

  getApproveDummyTest: createUrl('admin/dummy_test_reject_approve/'),

  updateEmail: createUrl('user/update_email/'),
  userVerfiyEmail: createUrl('user/verify_emails/'),

  takeATest: createUrl('test/take_a_test/'),
  checkTestAvailability: createUrl('test/check_availability/'),

  // new update email api
  changeEmail: createUrl('user/change_email/'),
  validateEmailOTP: createUrl('user/update_email_v2/'),

  // validate username
  validateUsername: createUrl('user/is_username_exists/'),

  userCount: createUrl('user/tester-count'), //https://api1.userpeek.io/user/tester-count

};
