import {Component, OnInit, Renderer2} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from "@angular/platform-browser";

import {AuthService} from 'src/app/shared/services/auth.service';
import {TokenParms} from 'src/app/core/models/token-parms';
import {DashboardClientService} from 'src/app/shared/services/dashboard-client.service';
import {TesterTestService} from 'src/app/shared/services/tester-test.service';
import {ToastrService} from 'ngx-toastr';
import {CustomPopupBoxService} from 'src/app/shared/services/custom-popup-box.service';
import { ToastMsgService } from 'src/app/shared/services/toast-msg.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  userName: any;
  loginForm: FormGroup;
  regForm: FormGroup;
  massage: string;
  Error = false;
  resetURL;
  urlReset: any;
  errorMsg: string;
  responseError: any;
  submitted: boolean = false;
  urlType: string;
  testerSignup;
  notUser: string;
  fieldTextType: boolean;
  message;
  checkEmailIsEmpty: boolean = false;
  checkPasswordIsEmpty: boolean = false;

  constructor(
    private _authServices: AuthService,
    private formbuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private _dashboardClient: DashboardClientService,
    public toastr: ToastrService,
    public toastMsgService: ToastMsgService,
    public _customPopupBoxService: CustomPopupBoxService,
    private _testerTestSer: TesterTestService,
    private titleService:Title,
  ) {this.titleService.setTitle("UserPeek Tester Login")}

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  ngOnInit() {
    this.route.url.subscribe((url) => (this.urlType = url[0].path));

    if (this.urlType === 'client') {
      this.testerSignup = true;
    } else {
      this.testerSignup = false;
    }
    // localStorage.setItem("role", this.urlType);

    this.setFormState();
    this.urlReset = this._authServices.url;


  }

  setFormState(): void {
    this.loginForm = this.formbuilder.group({
      // username: ['piyushrai2', [Validators.required]],
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[A-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      password: ['', [Validators.required]],
    });
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }


  onLogin() {
    let that = this;
    that.submitted = true;
    if (that.loginForm.invalid) {
      return;
    }
    that.submitted = true;

    if (!window.navigator.onLine) {
      this.responseError = 'Server is not responding';
    } else {
      window['grecaptcha'].ready(function() {
        window['grecaptcha']
          .execute('6LcXITEaAAAAAJ-PIRcGLjGH_EaZbplqPL7Xm8kX', {
            action: 'submit',
          })
          .then(function(token) {
            that.submitted = true;
            if (that.loginForm.invalid) {
              return;
            }
            that.submitted = true;
            let login = that.loginForm.value;
            login['captchaToken'] = token;
            that.login(login);
          });
      });
    }
  }

  // logi Api call

  login(login: TokenParms) {
    this._authServices.login(login).subscribe((employee) => {
      var succ = employee;
      // if (this.urlType == employee.usertype) {
      // if(localStorage.getItem('role')==employee.usertype){

      this._dashboardClient.setData(employee);

      localStorage.setItem('data', employee.username);
      localStorage.setItem('usertype', employee.usertype);
      localStorage.setItem('user_id', employee.user_id);

      var status = employee['status'];
      var usertype = employee['usertype'];
      this.responseError = employee['message'];
      if (status == 200 && usertype == 'tester') {
        this.loginForm.reset();
        localStorage.setItem('token_id', employee['key']);
        this.router.navigate(['dashboard']);
        this.showSuccessToster();
      } else if (status == 401 || status == 400) {
        this.responseError = this.responseError;
        // this.responseError = "No account using these credentials found. Please check email and password";
      } else {
        this.Error = true;
        this.responseError =
          'No account using these credentials found. Please check email and password';
      }

      // }else{
      //   this.notUser = "This account not for  " + localStorage.getItem('role') +" login" + " or Please check email and password"
      // }
      // }

      (err) => {
        this.errorMsg = err;
      };
    });
  }

  showSuccessToster() {

    // this.toastr.success('Login successful!', 'Success!');
    this.toastMsgService.showMsg("success", "Log in successful");

    // this._testerTestSer.approvedTest().subscribe((res)=>{
    //   if(res.data[0].is_approved == false){
    //     this._testerTestSer.getDummyTest().subscribe((availableTestRes) => {
    //       // Show trail test reject status in popup based on no of count
    //       if(availableTestRes.data[0].dummy_count == 1){ // one trail test rejected
    //         this._customPopupBoxService.showPopup("trail-test-1-rejected");
    //       } else if(availableTestRes.data[0].dummy_count == 2){ // two trail test rejected
    //         this._customPopupBoxService.showPopup("trail-test-2-rejected");
    //       }
    //     });
    //   }
    // })
    this._testerTestSer.approvedTest().subscribe((res)=>{
      if(res.data.length > 0){
        if(res.data.length == 1){
          if(res.data[0].status === "REJECTED"){
            // show popup
            this.showTrailTestLimitPopup();
          }
        }
        else if(res.data.length == 2){
          if(res.data[0].status === "REJECTED"){
            if(res.data[1].status === "REJECTED"){
              // show popup
              this.showTrailTestLimitPopup();
            }
          }
          else if(res.data[0].status === "APPROVED"){
            if(res.data[1].status === "REJECTED"){
              // show popup
              this.showTrailTestLimitPopup();
            }
          }
        }
      }
    })
  }


  showTrailTestLimitPopup(){
    this._testerTestSer.getDummyTest().subscribe((availableTestRes) => {
      // Show trail test reject status in popup based on no of count
      if(availableTestRes.data[0].dummy_count == 1){ // one trail test rejected
        this._customPopupBoxService.showPopup("trail-test-1-rejected");
      } else if(availableTestRes.data[0].dummy_count == 2){ // two trail test rejected
        this._customPopupBoxService.showPopup("trail-test-2-rejected");
      }
    });
  }

  //  Reset Password

  resetpass() {
    this._authServices.forgetPassword().subscribe((res) => {
      this.resetURL = res;
      this.router.navigate(['res']);
    });
  }





}
