import {environment} from './../environments/environment';
import {EventEmitter, Injectable} from '@angular/core';
import * as io from 'socket.io-client';
import {Observable, Subject, Subscription} from 'rxjs';

// import { Observable } from "rxjs/Observable";

@Injectable({
  providedIn: 'root',
})
export class SocketioService {
  public socket;
  public stream: MediaStream;
  public startRecoIndex: any;
  public matchIndex: any;
  public chunks = [];
  // public stream = new Subject<MediaStream>()
  invokeFirstComponentFunction = new EventEmitter();
  subsVar: Subscription;
  private subject = new Subject<any>();

  constructor() {
  }

  connectionInit() {
    this.socket = io(environment.SOCKET_ENDPOINT);
    // let observable = new Observable(observer => {
    //   this.socket.on('blob_received', )
    // })
  }

  sendMessage(message) {
    this.subject.next(message);
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  emit(event, payload) {
    this.socket.emit(event, payload);
  }
}
