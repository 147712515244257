import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {TesterRoutingModule} from './tester-routing.module';
import {CompleteTesterProfileComponent} from './complete-tester-profile/complete-tester-profile.component';
import {TesterTestsComponent} from './tester-tests/tester-tests.component';
import {ImageCroppperComponent} from './../../shared/image-cropper/image-cropper.component';
import {NgxSpinnerModule} from 'ngx-spinner';

import {SharedModule} from 'src/app/shared/shared.module';
import {DashboardComponent} from 'src/app/modules/tester/dashboard/dashboard.component';
import {RecordingOverlayComponent} from './recording-overlay/recording-overlay.component';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {NgxPaginationModule} from 'ngx-pagination';
import {ValidatePasscodeBoxComponent} from './validate-passcode-box/validate-passcode-box.component';

@NgModule({
  declarations: [
    ImageCroppperComponent,
    CompleteTesterProfileComponent,
    DashboardComponent,

    TesterTestsComponent,
    RecordingOverlayComponent,
    ValidatePasscodeBoxComponent,
  ],
  imports: [
    CommonModule,
    TesterRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    SharedModule,
    NgxPaginationModule,
    NgCircleProgressModule.forRoot({
      radius: 60,
      outerStrokeWidth: 10,
      innerStrokeWidth: 5,
      showBackground: false,
      startFromZero: false,
    }),
  ],
  exports: [
    ImageCroppperComponent,
    TesterTestsComponent,
    RecordingOverlayComponent,
  ],
})
export class TesterModule {
}
